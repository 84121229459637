import { ApiCall } from "../apiBase";

class NotificationService {
  async createNotification(notificationObject) {
    const result = await ApiCall({
      method: "post",
      data: {
        query: `
        mutation createNotification($notificationObject: NotitcificationInput!){
          createNotification(notification: $notificationObject) {
            message
            status
          }
        }
        `,
        variables: {
          notificationObject
        }
      }
    });

    if (result?.data?.data?.createNotification && !result?.data?.errors) {
      return result.data.data.createNotification;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  async getAllNotifications() {
    const result = await ApiCall({
      method: "post",
      data: {
        query: `
        query getAllNotifications{
          getAllNotifications {
            title
            description
            url
            createdDate
          }
        }
        `
      }
    });

    if (result?.data?.data?.getAllNotifications && !result?.data?.errors) {
      return result.data.data.getAllNotifications;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  async getNotificationsForLoggedinUser() {
    const result = await ApiCall({
      method: "post",
      data: {
        query: `
        query getNotificationsForLoggedinUser{
          getNotificationsForLoggedinUser {
            id
            title
            description
            url
            readed
            createdDate
            type
          }
        }
        `
      }
    });

    if (
      result?.data?.data?.getNotificationsForLoggedinUser &&
      !result?.data?.errors
    ) {
      return result.data.data.getNotificationsForLoggedinUser;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  async markNotificationAsReaded(notificationId) {
    const result = await ApiCall({
      method: "post",
      data: {
        query: `
        mutation markNotificationAsReaded($notificationId: ID!){
          markNotificationAsReaded(notificationId: $notificationId) {
            message
            status
          }
        }
        `,
        variables: {
          notificationId
        }
      }
    });

    if (result?.data?.data?.markNotificationAsReaded && !result?.data?.errors) {
      return result.data.data.markNotificationAsReaded;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }
}
export default new NotificationService();
