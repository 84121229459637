export const ERROR_CODES = {
  GENERAL_ERROR: "GENERAL_ERROR",
  FILE_DOWNLOAD_FAILED: "FILE_DOWNLOAD_FAILED",
  S3_FILE_UPLOAD_FAILED: "S3_FILE_UPLOAD_FAILED",
  FILE_DELETE_FAILED: "FILE_DELETE_FAILED",
  VALUES_ARE_ZERO: "VALUES_ARE_ZERO",
  CANNOT_PERFORM_SCORE_CALCULATION: "CANNOT_PERFORM_SCORE_CALCULATION",
  CANNOT_MAP_SCORE: "CANNOT_MAP_SCORE",
  ANSWER_NOT_ACCESSIBLE: "ANSWER_NOT_ACCESSIBLE",
  ANSWER_INDEXES_MUST_BE_NUMBERS: "ANSWER_INDEXES_MUST_BE_NUMBERS",
  INVALID_ID: "INVALID_ID",
  USER_ALREADY_EXISTING: "USER_ALREADY_EXISTING",
  EMAIL_SENDING_FAILED: "EMAIL_SENDING_FAILED",
  UNAUTHENTICATED: "UNAUTHENTICATED", //login failed
  UNAUTHORIZED: "UNAUTHORIZED", // doesn't have rights
  USER_NOT_FOUND: "USER_NOT_FOUND",
  USER_NOT_ACTIVATED: "USER_NOT_ACTIVATED",
  AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
  COMPANY_NOT_ACTIVATED: "COMPANY_NOT_ACTIVATED",
  COMPANY_DEACTIVATED: "COMPANY_DEACTIVATED",
  COMPANY_NOT_FOUND: "COMPANY_NOT_FOUND",
  RESET_TOKEN_EXPIRED: "RESET_TOKEN_EXPIRED",
  TOKEN_NOT_FOUND: "TOKEN_NOT_FOUND",
  OLD_PASSWORDS_DONT_MATCH: "OLD_PASSWORDS_DONT_MATCH",
  USER_ALREADY_ACTIVATED: "USER_ALREADY_ACTIVATED",
  FILEKEY_ALREADY_EXISTS: "FILEKEY_ALREADY_EXISTS",
  NO_DATAFILE_FOUND: "NO_DATAFILE_FOUND",
  EXCEEDING_COMPANY_CONTRACT: "EXCEEDING_COMPANY_CONTRACT",
  BALANCE_NOT_ENOUGH: "BALANCE_NOT_ENOUGH",
  DATAFILE_NOT_CLEANED: "DATAFILE_NOT_CLEANED",
  INCOMPLETE_INPUT: "INCOMPLETE_INPUT",
  INVALID_PARAMETERS: "INVALID_PARAMETERS",
  DATARECORD_NOT_FOUND: "DATARECORD_NOT_FOUND",
  NO_RECORDS_FOUND: "NO_RECORDS_FOUND",
  DATAPOINT_NOT_FOUND: "DATAPOINT_NOT_FOUND",
  DATAFILE_UPLOAD_FAILED: "DATAFILE_UPLOAD_FAILED",
  SCORES_NOT_AVAILABLE: "SCORES_NOT_AVAILABLE",
  TARGET_SAMPLE_SIZE_TOO_SMALL: "TARGET_SAMPLE_SIZE_TOO_SMALL",
  DATAFILE_STRUCTURE_IS_DIFFERENT: "DATAFILE_STRUCTURE_IS_DIFFERENT",
  QUOTA_SETTINGS_NOT_SAVED: "QUOTA_SETTINGS_NOT_SAVED",
  NUMBER_OF_CLEANED_RECORDS_ZERO: "NUMBER_OF_CLEANED_RECORDS_ZERO",
  NO_OPEN_QUESTIONS_FOUND: "NO_OPEN_QUESTIONS_FOUND",
  OES_API_ERROR: "OES_API_ERROR",
  HEADER_ROW_HAS_DUPLICATES: "HEADER_ROW_HAS_DUPLICATES",
  CATEGORIES_NOT_AVAILABLE: "CATEGORIES_NOT_AVAILABLE",
  GPT3_NOT_INITIALIZED: "GPT3_NOT_INITIALIZED",
  UPLOAD_GOING_ON: "UPLOAD_GOING_ON",
  DRID_EMPTY_COLUMN: "DRID_EMPTY_COLUMN",
  DRID_ONE_OR_MORE_VALUES_EMPTY: "DRID_ONE_OR_MORE_VALUES_EMPTY",
  DRID_DUPLICATES_FOUND: "DRID_DUPLICATES_FOUND",
  DRID_MISSING_VALUES: "DRID_MISSING_VALUES",
  ITEM_BATTERY_UNDER_MINIMUM: "ITEM_BATTERY_UNDER_MINIMUM",
  ITEMS_NON_NUMERIC: "ITEMS_NON_NUMERIC",
  ITEM_COLUMN_EMPTY: "ITEM_COLUMN_EMPTY",
  TIMESTAMP_COLUMN_EMPTY: "TIMESTAMP_COLUMN_EMPTY",
  TIMESTAMP_NON_NUMERIC: "TIMESTAMP_NON_NUMERIC",
  GROUPING_COLUMN_EMPTY: "GROUPING_COLUMN_EMPTY",
  OPEN_ENED_ANSWER_COLUMN_EMPTY: "OPEN_ENED_ANSWER_COLUMN_EMPTY",
  OPEN_ENED_ANSWER_CONTAINS_ONLY_NUMBERS: "OPEN_ENED_ANSWER_CONTAINS_ONLY_NUMBERS",
  VOTE_COLUMN_EMPTY: "VOTE_COLUMN_EMPTY",
  VOTE_NON_NUMERIC: "VOTE_NON_NUMERIC",
  PREDICTION_COLUMN_EMPTY: "PREDICTION_COLUMN_EMPTY",
  PREDICTION_NON_NUMERIC: "PREDICTION_NON_NUMERIC",
  SERVER_ERROR: "SERVER_ERROR",
  SHEET_NOT_FOUND: "SHEET_NOT_FOUND"
};
