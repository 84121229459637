import store from "@/state/store";
import cookie from "vue-cookies";

//alert before 5mins
const ALERT_DURATION=300000;

export const state = {
  currentUser: cookie.get("accessToken"),
  expireAlertOpen: false,
  expireAlertConsent: false,
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue.token;
  },

  SET_EXPIRE_ALERT_OPEN(state) {
    const alreadyOpened = window.localStorage.getItem("WAITING_FOR_LOGOUT");
    if(!alreadyOpened){
      state.expireAlertConsent = false;
      state.expireAlertOpen = true;
    }
  },
  SET_EXPIRE_ALERT_CONCENT(state) {
    state.expireAlertOpen = false;
    state.expireAlertConsent = true;
    window.localStorage.setItem("WAITING_FOR_LOGOUT", true);
  }
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },

  expireAlert(state) {
    return !!state.expireAlertOpen;
  },

  expireAlertConsent(state) {
    return !!state.expireAlertConsent;
  }
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({
    dispatch
  }) {
    dispatch("validate");
  },

  // Logs in the current user.
  logIn({
    commit
  }, {
    token
  } = {}) {
    const user = {};
    user.token = token;
    commit("SET_CURRENT_USER", user);
  },

  // Logs out the current user.
  logOut({
    state
  }) {
    // eslint-disable-next-line no-unused-vars
    window.localStorage.removeItem("WAITING_FOR_LOGOUT");
    cookie.remove("accessToken");
    cookie.remove("tokenExpireDate");
    state.currentUser = null;
  },

  // Validates the current user's token to see if it is there and if it expired and removes it if so
  // eslint-disable-next-line no-unused-vars
  validate() {
    const token = cookie.get("accessToken");
    const tokenExpire =cookie.get("tokenExpireDate");
    const tokenExpireJS = new Date(tokenExpire);
    const toDayDateJS = new Date();
    const tokenExpireJSConvert = tokenExpireJS.getTime();
    const toDayDateJSConvert = toDayDateJS.getTime();

    if (tokenExpire && token) {
      if(tokenExpireJSConvert - ALERT_DURATION < toDayDateJSConvert){
        if (!store.getters["auth/expireAlert"] && !store.getters["auth/expireAlertConsent"]) {
          store.dispatch("auth/openExpireAlert");
        }
      }

      if (tokenExpireJSConvert < toDayDateJSConvert) {
        cookie.remove("tokenExpireDate");
        window.localStorage.removeItem("WAITING_FOR_LOGOUT");
        window.location.href = "/login";
      }
      return token;
    } else {
      state.currentUser = null;
      return null;
    }
  },

  //custom dispatch to trigger modal
  openExpireAlert({
    commit
  }) {
    commit("SET_EXPIRE_ALERT_OPEN");
  },

  updateExpireAlertConsent({
    commit
  }) {
    commit("SET_EXPIRE_ALERT_CONCENT");
  }
};