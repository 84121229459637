<script>
import { sameAs, minLength, required } from "vuelidate/lib/validators";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import ResponseStatus from "@/shared/enums/responseStatus";
import AuthService from "@/api/services/auth.service";
import { encrypt } from "@/shared/crypto/crypto-helpers";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    organization: Object
  },
  data() {
    return {
      /******** Data Variables ******/
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      /******** Operational Variables *******/
      isChangePasswordSuccess: false,
      isChangePasswordError: false,
      changePasswordErrorMessage: null
    };
  },
  validations: {
    currentPassword: { required },
    newPassword: { required, minLength: minLength(8) },
    confirmPassword: { required, sameAsPassword: sameAs("newPassword") }
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    clickChangePassword() {
      // stop here if form is invalid
      this.$v.$touch();

      if(this.$v.$invalid) {
        this.isUpdateOrganizationError = true;
        this.updateOrganizationErrorMessage = this.$i18n.t("adminComponents.changePasswordOverlay.validationError");
      } else {
        this.changePassword();
      }
    },
    async changePassword() {
      try {
        const encryptedCurrentPassword = await encrypt(Buffer.from(this.currentPassword));
        let response = await AuthService.changePassword(encryptedCurrentPassword, this.newPassword);
        if (response.status === ResponseStatus.SUCCESS) {
          this.isChangePasswordSuccess = true;
          setTimeout(() => {
            this.isChangePasswordSuccess = false;

            // reset the values
            this.resetValuesToInitialState();
            this.$emit("close");
          }, 2000);
        }
      } catch (error) {
        this.isChangePasswordError = true;
        this.changePasswordErrorMessage = await getErrorMessageByErrorCode(error.code)
      }
    },
    resetValuesToInitialState() {
      this.currentPassword = null;
      this.newPassword = null;
      this.confirmPassword = null;
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    size="md"
  >
    <!-- Title -->
    <div class="row">
      <div class="col-md-10">
        <div style="font-size: medium; font-weight: 600"> {{$t("adminComponents.changePasswordOverlay.title")}} </div>
        <div class="mt-2"> {{$t("adminComponents.changePasswordOverlay.description")}}</div>
      </div>
      <div class="col-md-2 text-right">
        <i class='bx bx-x' style="font-size: x-large; cursor: pointer;" @click="clickClose"></i>
      </div>
      <div class="col-md-12"> <hr /> </div>
      
    </div>

    <!-- Form -->
    <div class="mt-2">
      <!-- Error Message -->
      <b-alert
        v-model="isChangePasswordError"
        class="mt-3 mb-3"
        variant="danger"
        dismissible
        >{{ changePasswordErrorMessage }}</b-alert
      >

      <!-- Success Message -->
      <b-alert
        v-model="isChangePasswordSuccess"
        class="mt-3 mb-3"
        variant="success"
      >
        <i class='bx bx-check-double bx-tada mr-1' ></i>
        {{$t("adminComponents.changePasswordOverlay.successMessage")}}
      </b-alert>

      <b-form-group id="current-password-group" label-for="currentPassword">
        <template v-slot:label>
          {{$t("adminComponents.changePasswordOverlay.form.currentPassword.label")}}
          <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="currentPassword"
          v-model="currentPassword"
          type="password"
          :placeholder="this.$i18n.t('adminComponents.changePasswordOverlay.form.currentPassword.placeholder')"
          :class="{ 'is-invalid':$v.currentPassword.$error }"
        ></b-form-input>
        <div v-if="$v.currentPassword.$error" class="invalid-feedback">
          <span v-if="!$v.currentPassword.required">
            {{$t("adminComponents.changePasswordOverlay.form.currentPassword.required")}}
          </span>
        </div>
      </b-form-group>

      <b-form-group id="new-password-group" label-for="newPassword">
        <template v-slot:label>
          {{$t("adminComponents.changePasswordOverlay.form.newPassword.label")}}
          <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="newPassword"
          v-model="newPassword"
          type="password"
          :placeholder="this.$i18n.t('adminComponents.changePasswordOverlay.form.newPassword.placeholder')"
          :class="{ 'is-invalid':$v.newPassword.$error }"
        ></b-form-input>
        <div v-if="$v.newPassword.$error" class="invalid-feedback">
          <span v-if="!$v.newPassword.required">
            {{$t("adminComponents.changePasswordOverlay.form.newPassword.required")}}
          </span>
          <span v-if="!$v.confirmPassword.minLength">
            {{$t("adminComponents.changePasswordOverlay.form.newPassword.minLength")}}
          </span>
        </div>
      </b-form-group>

      <b-form-group id="confirm-password-group" label-for="confirmPassword">
        <template v-slot:label>
          {{$t("adminComponents.changePasswordOverlay.form.confirmPassword.label")}}
          <span class="text-danger">*</span>
        </template>
        <b-form-input
          id="confirmPassword"
          v-model="confirmPassword"
          type="password"
          :placeholder="this.$i18n.t('adminComponents.changePasswordOverlay.form.confirmPassword.placeholder')"
          :class="{ 'is-invalid':$v.confirmPassword.$error }"
        ></b-form-input>
        <div v-if="$v.confirmPassword.$error" class="invalid-feedback">
          <span v-if="!$v.confirmPassword.required">
            {{$t("adminComponents.changePasswordOverlay.form.confirmPassword.required")}}
          </span>
          <span v-if="!$v.confirmPassword.sameAsPassword">
            {{$t("adminComponents.changePasswordOverlay.form.confirmPassword.notMatch")}}
          </span>
        </div>
      </b-form-group>

    </div>

    <!-- Actions -->
    <div class="row mt-5">
      <div class="col-md-12">
        <b-button variant="primary" @click="clickChangePassword">
          {{$t("adminComponents.changePasswordOverlay.changePasswordButton")}}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
