<script>
import contractService from '@/api/services/contract.service';
import ContractCard from './contract-card.vue';
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import { EventBus } from "@/shared/util/event-bus.js";


export default {
  props: {
    organization: Object
  },
  components: {
    ContractCard
  },
  data() {
    return {
      /***** Data variables *****/
      contracts: null,
      contractsToDisplay: [],
      showAllActiveContract: true,
      showAllUpcomingContracts: true,
      showAllExpiredContracts: true,
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false
    }
  },
  async mounted() {
    this.isComponentLoading = true;
    await this.fetchOrganizationContracts();
    this.isComponentLoading = false;
    EventBus.$emit("contractComponetGetLoaded");
  },
  methods: {
    async fetchOrganizationContracts() {
      try {
        this.contracts = null;

        this.contracts = await contractService.getCompanyContracts(this.organization.id);
        await this.setDataForDisplay();
      } catch(error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    setDataForDisplay() {
      this.contractsToDisplay = this.contracts;
    },
    selectContractToDisplayDetails(contractToDisplayDetails) {
      EventBus.$emit("chanageSelectedContractToDisplayDetails", contractToDisplayDetails);
    }
  }
}
</script>

<template>
  <div class="card" style="height: 610px">
    <div class="card-body">
      <div class="card-text">
        
        <div class="row align-items-center">
          <div class="col-4">
            <span style="font-size: medium; font-weight: 600"> {{$t("adminComponents.contractsWidget.title")}} </span>
          </div>
          <div class="col-8 text-right">
            <b-form-checkbox
              v-model="showAllActiveContract"
              @change="selectAllReceivedTransactions"
              style="display: inline-block; margin-right: 10px; text-align: right;"
            >
              {{$t("adminComponents.contractsWidget.contractStatus.active")}}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="showAllUpcomingContracts"
              @change="selectAllUsedTransactions"
              style="display: inline-block; margin-right: 10px; text-align: right;"
            >
              {{$t("adminComponents.contractsWidget.contractStatus.upcoming")}}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="showAllExpiredContracts"
              @change="selectAllUsedTransactions"
              style="display: inline-block; margin-right: 10px; text-align: right;"
            >
              {{$t("adminComponents.contractsWidget.contractStatus.expired")}}
            </b-form-checkbox>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 mb-2" v-for="(contract, contractIndex) in contractsToDisplay" :key="contractIndex" @click="selectContractToDisplayDetails(contract)">
            <ContractCard :contract="contract" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>