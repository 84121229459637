var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { attrs: { id: "page-topbar" } },
    [
      _c("div", { staticClass: "navbar-header" }, [
        this.loggedInUser && this.company
          ? _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                { staticClass: "navbar-brand-box" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "logo logo-light",
                      attrs: { tag: "a", to: "/" },
                    },
                    [
                      _c("span", { staticClass: "logo-sm" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/Redem Icon.png"),
                            alt: "",
                            height: "25",
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "logo-lg" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/Redem-light.png"),
                            alt: "",
                            height: "30",
                          },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm px-3 font-size-16 header-item",
                  attrs: { id: "vertical-menu-btn", type: "button" },
                  on: { click: _vm.toggleMenu },
                },
                [_c("i", { staticClass: "fa fa-fw fa-bars" })]
              ),
              _c(
                "div",
                { staticClass: "dropdown d-none d-lg-inline-block ml-1" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mr-3 mx-lg-auto mb-4 float-left float-lg-none",
                    },
                    [
                      _vm.userCompany !== null && _vm.userCompany.image
                        ? _c("div", { staticClass: "pt-3" }, [
                            _c("img", {
                              staticStyle: { "max-height": "30px" },
                              attrs: { src: _vm.userCompany.image },
                            }),
                          ])
                        : _c(
                            "div",
                            { staticClass: "avatar-title bg-white pt-3" },
                            [
                              _c("img", {
                                staticClass: "header-profile-user",
                                staticStyle: { width: "40px", height: "40px" },
                                attrs: {
                                  src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg",
                                },
                              }),
                            ]
                          ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "dropdown d-none d-lg-inline-block ml-2" },
                [
                  _c("div", { staticClass: "pt-4" }, [
                    _vm._v(_vm._s(this.userCompany.name)),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        this.loggedInUser && this.company && this.showNotifications
          ? _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      variant: "white",
                      right: "",
                      "toggle-class": "header-item",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("img", {
                                staticClass: "mr-1",
                                attrs: {
                                  src: _vm.flag,
                                  alt: "Header Language",
                                  height: "16",
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.text) + " "),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      254659680
                    ),
                  },
                  _vm._l(_vm.languages, function (entry, i) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: `Lang${i}`,
                        staticClass: "notify-item",
                        class: { active: _vm.lan === entry.language },
                        attrs: { value: entry },
                        on: {
                          click: function ($event) {
                            return _vm.setLanguage(
                              entry.language,
                              entry.title,
                              entry.flag
                            )
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "me-1",
                          attrs: {
                            src: `${entry.flag}`,
                            alt: "user-image",
                            height: "12",
                          },
                        }),
                        _c("span", { staticClass: "align-middle" }, [
                          _vm._v(_vm._s(entry.title)),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
                this.loggedInUser.accessLevel !== "SUPER_ADMIN"
                  ? _c(
                      "b-dropdown",
                      {
                        attrs: {
                          variant: "black",
                          "toggle-class": "header-item noti-icon",
                          "menu-class": "dropdown-menu-lg p-0",
                          right: "",
                          lazy: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c("i", { staticClass: "bx bx-wallet" }),
                                  _vm.showCreditNotification
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge bg-danger rounded-pill text-danger",
                                          staticStyle: { "font-size": "10px" },
                                        },
                                        [_vm._v("o")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1139788968
                        ),
                      },
                      [
                        _c("div", { staticClass: "p-3" }, [
                          _c("div", { staticClass: "row align-items-center" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("h6", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("menus.walletMenu.dropdown.title")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                          this.loggedInUser.company.contractStatus === "RUNNING"
                            ? _c(
                                "div",
                                [
                                  _c("apexchart", {
                                    ref: "creditChart",
                                    staticClass: "apex-charts apexChartData",
                                    attrs: {
                                      type: "radialBar",
                                      height: "250",
                                      dir: "ltr",
                                      series: [
                                        Math.round(
                                          this.chartDataForCreditBalanceChart
                                        ),
                                      ],
                                      options:
                                        this.creditBalanceChartOptions
                                          .chartOptions,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.loggedInUser.company.contractStatus === "RUNNING"
                            ? _c(
                                "div",
                                { staticClass: "row align-items-center mt-4" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-12 text-center" },
                                    [
                                      this.company.contract.name === "Unlimited"
                                        ? _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "m-0 mb-1 text-success",
                                            },
                                            [_vm._v(" Unlimited ")]
                                          )
                                        : _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "m-0 mb-1 text-success",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    this.company.wallet.balance.toLocaleString(
                                                      _vm.localeFormat,
                                                      {
                                                        minimumFractionDigits: 0,
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "menus.walletMenu.dropdown.balance"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          this.loggedInUser.company.contractStatus === "PAUSED"
                            ? _c("div", { staticClass: "row mt-5" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12 text-center" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "text-danger" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "menus.walletMenu.dropdown.pausedContract.text1"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "menus.walletMenu.dropdown.pausedContract.text2"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          this.loggedInUser.accessLevel !== "EMPLOYEE"
                            ? _c("div", { staticClass: "row mt-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "btn",
                                        staticStyle: { width: "100%" },
                                        attrs: { variant: "light" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.redirectToCreditManagment()
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "bx bx-money mr-1",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "menus.walletMenu.dropdown.button.creditManagement"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      variant: "black",
                      "toggle-class": "header-item noti-icon",
                      "menu-class": "dropdown-menu-lg p-0",
                      right: "",
                      lazy: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("i", {
                                staticClass: "bx bx-bell",
                                class:
                                  _vm.unReadNotificationCount > 0
                                    ? "bx-tada"
                                    : "",
                              }),
                              _vm.unReadNotificationCount > 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge bg-blue2 rounded-pill text-white",
                                      staticStyle: { "font-size": "10px" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.unReadNotificationCount)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2207330143
                    ),
                  },
                  [
                    _c("div", { staticClass: "p-3" }, [
                      _c("div", { staticClass: "row align-items-center" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("h6", { staticClass: "m-0" }, [
                            _vm._v(
                              _vm._s(_vm.$t("general.notification.title"))
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "p-3" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c(
                              "simplebar",
                              {
                                staticStyle: {
                                  "max-height": "300px",
                                  "overflow-x": "hidden",
                                },
                              },
                              _vm._l(
                                this.notificationToDisplay,
                                function (notification, notificationIndex) {
                                  return _c(
                                    "div",
                                    {
                                      key: notificationIndex,
                                      staticClass: "mb-3",
                                    },
                                    [
                                      notification.type ===
                                      _vm.notificationTypes.UPLOAD_FINISHED
                                        ? _c("div", [
                                            _c("h6", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    notification.title +
                                                      " " +
                                                      _vm.$t(
                                                        "general.notification.datafileUploadSuccessTitle"
                                                      )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.notification.datafileUploadSuccessText"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-6" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-blue2 font-weight-bold",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clickUploadSuccessNotification(
                                                              notification.id,
                                                              notification.url
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "general.notification.datafileUploadSuccessLink"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-6 text-right",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            new Date(
                                                              notification.createdDate
                                                            ).toLocaleDateString(
                                                              "de-DE"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("hr"),
                                          ])
                                        : notification.type ===
                                          _vm.notificationTypes.UPDATE_FINISHED
                                        ? _c("div", [
                                            _c("h6", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    notification.title +
                                                      " " +
                                                      _vm.$t(
                                                        "general.notification.datafileUpdateSuccessTitle"
                                                      )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.notification.datafileUpdateSuccessText"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-6" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-blue2 font-weight-bold",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clickUpdateSuccessNotification(
                                                              notification.id,
                                                              notification.url
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "general.notification.datafileUpdateSuccessLink"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-6 text-right",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            new Date(
                                                              notification.createdDate
                                                            ).toLocaleDateString(
                                                              "de-DE"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("hr"),
                                          ])
                                        : notification.type ===
                                          _vm.notificationTypes.UPLOAD_ERROR
                                        ? _c("div", [
                                            _c("h6", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    notification.title +
                                                      " " +
                                                      _vm.$t(
                                                        "general.notification.datafileUploadErrorTitle"
                                                      )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.notification.datafileUploadErrorText"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-6" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-blue2 font-weight-bold",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clickUploadErrorNotification(
                                                              notification.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "general.notification.datafileUploadErrorLink"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-6 text-right",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            new Date(
                                                              notification.createdDate
                                                            ).toLocaleDateString(
                                                              "de-DE"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("hr"),
                                          ])
                                        : notification.type ===
                                          _vm.notificationTypes.UPDATE_ERROR
                                        ? _c("div", [
                                            _c("h6", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    notification.title +
                                                      " " +
                                                      _vm.$t(
                                                        "general.notification.datafileUpdateErrorTitle"
                                                      )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.notification.datafileUpdateErrorText"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-6" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-blue2 font-weight-bold",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clickUpdateErrorNotification(
                                                              notification.id,
                                                              notification.url
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "general.notification.datafileUpdateErrorLink"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-6 text-right",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            new Date(
                                                              notification.createdDate
                                                            ).toLocaleDateString(
                                                              "de-DE"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("hr"),
                                          ])
                                        : _c("div", [
                                            _c("h6", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(notification.title) +
                                                  " "
                                              ),
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    notification.description
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "row mt-3" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-6" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-blue2 font-weight-bold",
                                                        attrs: {
                                                          href: notification.url,
                                                          target: "_blank",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.clickReadMoreNotificationButton(
                                                              notification.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "general.notification.redeaMore"
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-md-6 text-right",
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            new Date(
                                                              notification.createdDate
                                                            ).toLocaleDateString(
                                                              "de-DE"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("hr"),
                                          ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    this.notificationToDisplay.length === 0
                      ? _c("div", { staticClass: "p-3" }, [
                          _c("div", { staticClass: "row mb-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-12 text-center mb-2" },
                              [
                                _c("i", {
                                  staticClass:
                                    "bx bx-bell-off display-4 text-mid",
                                }),
                              ]
                            ),
                            _c("div", { staticClass: "col-12 text-center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "general.notification.noNewNotification"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "dropdown d-none d-lg-inline-block ml-1" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn header-item noti-icon",
                        attrs: { type: "button" },
                        on: { click: _vm.initFullScreen },
                      },
                      [_c("i", { staticClass: "bx bx-fullscreen" })]
                    ),
                  ]
                ),
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      variant: "black",
                      "toggle-class": "header-item noti-icon",
                      right: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("i", { staticClass: "bx bx-info-circle" }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2518952201
                    ),
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "text-dark dropdown-item",
                        attrs: {
                          tag: "span",
                          href: "https://redem-resources.s3.eu-central-1.amazonaws.com/two-pager/Redem%C2%AE+Data+Quality+Assurance+-+Kundeninformationen.pptx",
                          target: "_blank",
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "bx bx-diamond ont-size-16 align-middle mr-1",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("menus.navMenu.dropdown.onePager")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "text-dark dropdown-item",
                        attrs: {
                          tag: "span",
                          href: "https://help.redem.io",
                          target: "_blank",
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "bx bx-bulb ont-size-16 align-middle mr-1",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("menus.navMenu.dropdown.helpCenter")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "b-dropdown",
                  {
                    attrs: {
                      right: "",
                      variant: "black",
                      "toggle-class": "header-item",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _vm.userImage
                                ? _c("img", {
                                    staticClass:
                                      "rounded-circle header-profile-user",
                                    attrs: { src: _vm.userImage },
                                  })
                                : _c("img", {
                                    staticClass:
                                      "rounded-circle header-profile-user",
                                    attrs: {
                                      src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png",
                                    },
                                  }),
                              _c(
                                "span",
                                {
                                  staticClass: "d-none d-xl-inline-block ml-1",
                                },
                                [_vm._v(_vm._s(_vm.userFirstName) + " ")]
                              ),
                              _c("i", {
                                staticClass:
                                  "mdi mdi-chevron-down d-none d-xl-inline-block",
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      364047766
                    ),
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      { on: { click: _vm.clickEditProfile } },
                      [
                        _c("i", {
                          staticClass:
                            "bx bx-edit font-size-16 align-middle mr-1",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("menus.navMenu.dropdown.editProfile")
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "b-dropdown-item",
                      { on: { click: _vm.clickChangePassword } },
                      [
                        _c("i", {
                          staticClass:
                            "bx bx-key font-size-16 align-middle mr-1",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("menus.navMenu.dropdown.changePassword")
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: {
                          href: "mailto:support@redem.io?subject=Bug Report",
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "bx bx-bug ont-size-16 align-middle mr-1",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("menus.navMenu.dropdown.bugReport")) +
                            " "
                        ),
                      ]
                    ),
                    _c("b-dropdown-divider"),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item text-danger",
                        attrs: { href: "/logout" },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "bx bx-power-off font-size-16 align-middle mr-1 text-danger",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("menus.navMenu.dropdown.logout")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.isDisplayEditProfileOverlay === true
        ? _c("EditProfileOverlay", {
            attrs: {
              visible: _vm.isDisplayEditProfileOverlay,
              user: this.loggedInUser,
              isEditProfile: true,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayEditProfileOverlay = false
              },
              reload: _vm.reloadPage,
            },
          })
        : _vm._e(),
      _vm.isDisplayChangePasswordOverLay === true
        ? _c("ChnagePasswordOverlay", {
            attrs: {
              visible: _vm.isDisplayChangePasswordOverLay,
              user: this.loggedInUser,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayChangePasswordOverLay = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }