<script>
import { getCurrencyAccoringTolanguage, getErrorMessageByErrorCode, getCreditsAccoringTolanguage, getDateAccoringTolanguage } from "@/shared/util/helpers.js";
import { EventBus } from "@/shared/util/event-bus.js";
import CreditPlanService from '@/api/services/creditPlan.service.js';

export default {
  props: {
    organization: Object
  },
  data() {
    return {
      /***** Data variables *****/
      creditPlans: null,
      dataForTable: null,
      showAllActiveCreditPlans: true,
      showAllInactiveCreditPlans: true,
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false,
      dataFetchingnErrorMessage: null,
      /***** Table Data *****/
      fieldsForTable: [
        { 
          key: "id",
          label: "ID",
          thClass: "d-none",
          tdClass: "d-none"
        },
        {
          key: "date",
          label: this.$t("adminComponents.creditPlansWidget.tableHeaders.date"),
        },
        {
          key: "name",
          label: this.$t("adminComponents.creditPlansWidget.tableHeaders.name")
        },
        {
          key: "type",
          label: this.$t("adminComponents.creditPlansWidget.tableHeaders.type"),
          thClass: "d-none",
          tdClass: "d-none"
        },
        {
          key: "status",
          label: this.$t("adminComponents.creditPlansWidget.tableHeaders.status"),
        },
        {
          key: "balance",
          label: this.$t("adminComponents.creditPlansWidget.tableHeaders.balance"),
        },
        {
          key: "receive",
          label: this.$t("adminComponents.creditPlansWidget.tableHeaders.receive"),
        },
        {
          key: "pricePerCredit",
          label: this.$t("adminComponents.creditPlansWidget.tableHeaders.pricePerCredit"),
        },
        {
          key: "isExpiring",
          label: this.$t("adminComponents.creditPlansWidget.tableHeaders.expiring"),
        },
        {
          key: "isPayPerUsage",
          label: this.$t("adminComponents.creditPlansWidget.tableHeaders.payAsUsage"),
        },
        {
          key: "moreInfo",
          label: "",
        }
      ],
      currentPage: 1,
      perPage: 8
      ,
      filter: null
    }
  },
  computed: {
    rows() {
      return this.creditPlans.length;
    },
  },
  async mounted() {
    this.isComponentLoading = true;
    await this.fetchCreditPlans();
    this.isComponentLoading = false;
    EventBus.$emit("creditPlansComponetGetLoaded");
  },
  methods: {
    async fetchCreditPlans() {
      try {
        this.creditPlans = null;

        this.creditPlans = await CreditPlanService.getCompanyCreditPlans(this.organization.id);
        await this.setDataForTable();
      } catch(error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    setDataForTable(){
      this.dataForTable = []

      this.creditPlans.forEach(creditPlan => {
        let creditPlanToPush = {
          id: creditPlan.id,
          name: creditPlan.name,
          date: creditPlan.createdAt,
          type: creditPlan.type,
          status: creditPlan.status,
          balance: creditPlan.credits.balance,
          receive: creditPlan.credits.receive,
          pricePerCredit: creditPlan.pricePerCredit,
          isExpiring: creditPlan.isExpiring,
          isPayPerUsage: creditPlan.isPayPerUsage
        }

        if(creditPlanToPush.status === 'ACTIVE' && this.showAllActiveCreditPlans) {
          this.dataForTable.push(creditPlanToPush)
        }

        if(creditPlanToPush.status === 'INACTIVE' && this.showAllInactiveCreditPlans) {
          this.dataForTable.push(creditPlanToPush)
        }
      });
    },
    formatCredit(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCreditsAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
    formatDate(dateToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getDateAccoringTolanguage(dateToFormat, langaugeToFormat);
    },
    formatCurrency(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCurrencyAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
    async selectAllActiveCreditPlans() {
      await this.setDataForTable();
    },
    async selectAllInactiveCreditPlans() {
      await this.setDataForTable();
    }
  }
}
</script>

<template>
  <div class="card" style="height:610px">
    <!-- Component Loading -->
    <div class="card-body" v-if="isComponentLoading">
      <div class="card-text">
        <div class="row pt-5">
          <div class="col-md-12 text-center p-5">
            <b-spinner
              style="width: 5rem; height: 5rem"
              class="m-2"
              variant="mid"
              role="status"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>

    <!-- Display Data -->
    <div class="card-body" v-if="!isComponentLoading && this.creditPlans && this.creditPlans.length > 0">
      <div class="card-text">
        
        <div class="row">
          <div class="col-12">
            <span style="font-size: medium; font-weight: 600"> {{$t("adminComponents.creditPlansWidget.title")}} </span>
          </div>
        </div>

        <!-- Search Bar & Fliters -->
        <div class="row mt-4">
          <div class="col-3">
            <b-form-input v-model="filter" type="search" :placeholder="$t('adminComponents.creditPlansWidget.searchPlaceholder')" ></b-form-input>
          </div>
          <div class="col-9 text-right">
            <b-form-checkbox
              v-model="showAllActiveCreditPlans"
              @change="selectAllActiveCreditPlans"
              style="display: inline-block; margin-right: 10px; text-align: right;"
            >
              {{$t("adminComponents.creditPlansWidget.creditPlanStatus.active")}}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="showAllInactiveCreditPlans"
              @change="selectAllInactiveCreditPlans"
              style="display: inline-block; margin-right: 10px; text-align: right;"
            >
              {{$t("adminComponents.creditPlansWidget.creditPlanStatus.inActive")}}
            </b-form-checkbox>
          </div>
        </div>

        <!-- Data Table -->
        <div class="mt-4">
          <div class="table-responsive mb-0">
            <b-table
              :items="dataForTable"
              :fields="fieldsForTable"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :bordered="true"
              :filter="filter"
              :filter-included-fields="['description']"
            >
              <!-- Date Column -->
              <template v-slot:cell(date)="data">
                {{ formatDate(data.value) }}
              </template>

              <!-- Balance Column -->
              <template v-slot:cell(balance)="data">
                {{ formatCredit(data.value) }}
              </template>

              <!-- Received Column -->
              <template v-slot:cell(receive)="data">
                {{ formatCredit(data.value) }}
              </template>

              <!-- Status -->
              <template v-slot:cell(status)="data">
                <span
                  class="badge badge-soft-secondary font-size-12"
                  :class="{
                    'badge-soft-success': `${data.value}` === 'ACTIVE',
                    'badge-soft-danger': `${data.value}` === 'INACTIVE'
                  }"
                >
                  <span v-if="data.value === 'ACTIVE'"> {{$t("adminComponents.creditPlansWidget.creditPlanStatus.active")}} </span>
                  <span v-if="data.value === 'INACTIVE'"> {{$t("adminComponents.creditPlansWidget.creditPlanStatus.inActive")}} </span>
                </span>
              </template>

              <!-- Price Per Credit -->
              <template v-slot:cell(pricePerCredit)="data">
                {{ formatCurrency(data.value) }}
              </template>

              <!-- Expiring -->
              <template v-slot:cell(isExpiring)="data">
                <i class='bx bxs-check-circle text-success' style="font-size: medium;" v-if="data.value === true"></i>
                <span v-else> - </span>
              </template>

              <!-- isPayPerUsage -->
              <template v-slot:cell(isPayPerUsage)="data">
                <i class='bx bxs-check-circle text-success' style="font-size: medium;" v-if="data.value === true"></i>
                <span v-else> - </span>
              </template>

              <!-- More Info -->
              <template v-slot:cell(moreInfo)="scope">
                <i style="font-size: 15px; cursor: pointer;" class="bx bx-info-circle" @click="clickOrganizationinfo(scope.item)"></i>
              </template>
            </b-table>
        </div>
        </div>

        <!-- pagination -->
        <div class="row">
          <div class="col">
            <div
              class="
                dataTables_paginate
                paging_simple_numbers
                float-right
              "
            >
              <ul class="pagination pagination-rounded mb-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>