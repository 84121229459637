var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "md",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row d-flex align-items-center" }, [
        _c("div", { staticClass: "col-md-11" }, [
          _c(
            "div",
            { staticStyle: { "font-size": "medium", "font-weight": "600" } },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("adminComponents.editUserOverlay.title")) +
                  " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "col-md-1 text-center" }, [
          _c("i", {
            staticClass: "bx bx-x",
            staticStyle: { "font-size": "x-large", cursor: "pointer" },
            on: { click: _vm.clickClose },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c(
            "b-alert",
            {
              staticClass: "mt-3 mb-3",
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.isUpdateUserError,
                callback: function ($$v) {
                  _vm.isUpdateUserError = $$v
                },
                expression: "isUpdateUserError",
              },
            },
            [_vm._v(_vm._s(_vm.updateUserErrorMessage))]
          ),
          _c(
            "b-alert",
            {
              staticClass: "mt-3 mb-3",
              attrs: { variant: "success" },
              model: {
                value: _vm.isUpdateUserSuccess,
                callback: function ($$v) {
                  _vm.isUpdateUserSuccess = $$v
                },
                expression: "isUpdateUserSuccess",
              },
            },
            [
              _c("i", { staticClass: "bx bx-check-double bx-tada mr-1" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("adminComponents.editUserOverlay.updateSuccess")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "b-alert",
            {
              staticClass: "mt-3 mb-3",
              attrs: { variant: "success" },
              model: {
                value: _vm.isSendResetPasswordLinkSuccess,
                callback: function ($$v) {
                  _vm.isSendResetPasswordLinkSuccess = $$v
                },
                expression: "isSendResetPasswordLinkSuccess",
              },
            },
            [
              _c("i", { staticClass: "bx bx-check-double bx-tada mr-1" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "adminComponents.editUserOverlay.sendPasswordResetLinkSuccess"
                    )
                  ) +
                  " "
              ),
            ]
          ),
          _c("div", { staticClass: "media" }, [
            _c("div", { staticClass: "avatar-md mr-4" }, [
              _c(
                "span",
                {
                  ref: "userAvatarImagePreview",
                  staticClass: "avatar-preview-after-crop",
                },
                [
                  _c("canvas", {
                    ref: "userAvatarImageCanvas",
                    staticStyle: { "border-radius": "50%" },
                    attrs: {
                      id: "userAvatarImageCanvas",
                      height: "65",
                      width: "65",
                    },
                  }),
                  !_vm.croppedUserImageFile
                    ? _c(
                        "span",
                        {
                          staticClass: "avatar-title bg-white",
                          staticStyle: { "margin-top": "-70px" },
                        },
                        [
                          _vm.userImageURL
                            ? _c("img", {
                                staticClass: "rounded-circle",
                                attrs: {
                                  src: _vm.userImageURL,
                                  alt: "",
                                  height: "65",
                                },
                              })
                            : _c("img", {
                                staticClass: "rounded-circle",
                                attrs: {
                                  src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png",
                                  alt: "",
                                  height: "65",
                                },
                              }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]),
            _c("div", { staticClass: "media-body align-self-center" }, [
              _c("div", { staticClass: "text-muted" }, [
                _c("p", { staticClass: "mb-1" }, [
                  _c("input", {
                    ref: "userAvatarInput",
                    staticStyle: {
                      width: "0.1px",
                      opacity: "0",
                      "z-index": "-1",
                    },
                    attrs: {
                      id: "userAvatarInput",
                      type: "file",
                      name: "file",
                      accept: "image/*",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.userAvatarSelected()
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "text-info mr-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { for: "userAvatarInput" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.editUserOverlay.image.label"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "mb-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.editUserOverlay.image.description"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: { id: "first-name-group", "label-for": "firstName" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.editUserOverlay.form.firstName.label"
                                  )
                                ) +
                                " "
                            ),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v("*"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-form-input", {
                      class: { "is-invalid": _vm.$v.firstName.$error },
                      attrs: {
                        id: "firstName",
                        type: "text",
                        placeholder: _vm.$t(
                          "adminComponents.editUserOverlay.form.firstName.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.firstName,
                        callback: function ($$v) {
                          _vm.firstName = $$v
                        },
                        expression: "firstName",
                      },
                    }),
                    _vm.$v.firstName.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.firstName.required
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "adminComponents.editUserOverlay.form.firstName.required"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: { id: "last-name-group", "label-for": "lastName" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.editUserOverlay.form.lastName.label"
                                  )
                                ) +
                                " "
                            ),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v("*"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-form-input", {
                      class: { "is-invalid": _vm.$v.lastName.$error },
                      attrs: {
                        id: "lastName",
                        type: "text",
                        placeholder: _vm.$t(
                          "adminComponents.editUserOverlay.form.lastName.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.lastName,
                        callback: function ($$v) {
                          _vm.lastName = $$v
                        },
                        expression: "lastName",
                      },
                    }),
                    _vm.$v.lastName.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.lastName.required
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "adminComponents.editUserOverlay.form.lastName.required"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "col-md-12" }, [
              _c("label", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("adminComponents.editUserOverlay.form.APIKey")
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "input-group bg-light rounded" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.magicKey,
                      expression: "user.magicKey",
                    },
                  ],
                  staticClass: "form-control bg-transparent border-0",
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.user.magicKey },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.user, "magicKey", $event.target.value)
                    },
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", id: "button-addon2" },
                    on: {
                      click: function ($event) {
                        return _vm.copyAPIKeyToClipboard()
                      },
                    },
                  },
                  [_c("i", { staticClass: "bx bx-copy-alt" })]
                ),
              ]),
            ]),
          ]),
          !this.isEditProfile
            ? _c("div", { staticClass: "row mt-3" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        staticClass: "mt-2",
                        attrs: { name: "check-button", switch: "" },
                        model: {
                          value: _vm.adminAccess,
                          callback: function ($$v) {
                            _vm.adminAccess = $$v
                          },
                          expression: "adminAccess",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "adminComponents.editUserOverlay.form.adminAccess"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "row mt-5" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-button",
              { attrs: { variant: "primary" }, on: { click: _vm.clickSave } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("adminComponents.editUserOverlay.saveButton")
                    ) +
                    " "
                ),
              ]
            ),
            !this.isEditProfile
              ? _c(
                  "b-button",
                  {
                    staticClass: "ml-2",
                    attrs: { variant: "outline-primary" },
                    on: { click: _vm.clickResetPassword },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "adminComponents.editUserOverlay.passwordResetButton"
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            !this.isEditProfile && this.user.id !== this.organizationOwner.id
              ? _c(
                  "b-button",
                  {
                    staticClass: "ml-2",
                    attrs: { variant: "light" },
                    on: { click: _vm.clickDeleteUser },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("adminComponents.editUserOverlay.deleteButton")
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.displayImageCropperModal
        ? _c("UserImageCropperModal", {
            attrs: {
              visible: _vm.displayImageCropperModal,
              imageFile: _vm.selectedUserImageFile,
            },
            on: {
              close: function ($event) {
                _vm.displayImageCropperModal = false
              },
              apply: _vm.setCroppedUserImagePreview,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }