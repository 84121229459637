<script>
import AuthService from "@/api/services/auth.service.js";
import CompanyService from "@/api/services/company.service.js";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/main";
import AccessLevel from "@/shared/enums/aceessLevels.js";
import PageHeader from "@/shared/components/page-header";
import ActiveOrganizations from '../components/widgets/active-organizations.vue';
import NewOrganizations from "../components/widgets/new-organizations.vue";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";

/**
 * Dashboard
 */
export default {
  page: {
    title: "Admin Panel",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    ActiveOrganizations,
    NewOrganizations
  },
  data() {
    return {
      accessLevelEnum: AccessLevel,
      /***** Data variables *****/
      loggedInUser: null,
      organizationSummary: null,
      dataFetchingnErrorMessage: null,
      /****** Operational variable ******/
      isPageLoading: false,
      isFetchingDataError: false,
    };
  },
  computed: {
    computedItems() {
      var items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Admin Panel",
          active: true
        }
      ];
      return items;
    }
  },
  async mounted() {
    this.isPageLoading = true;
    await this.getLoginUser();
    await this.getCompaniesSummary();
    this.isPageLoading = false;
  },
  methods: {
    async getLoginUser() {
      try {
        this.loggedInUser = await AuthService.getLoggedInUser();
      } catch (error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    async getCompaniesSummary() {
      try {
        this.organizationSummary = await CompanyService.getCompaniesSummary();
      } catch (error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    }

  }
};
</script>

<template>
  <Layout>
    <!-- Page Loading -->
    <div
      class="row pt-5"
      v-if="isPageLoading"
    >
      <div class="col-md-12 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Display Organization Overview -->
    <div v-if="!isPageLoading && loggedInUser.accessLevel === accessLevelEnum.SUPER_ADMIN">
      <PageHeader title="Admin Panel" :items="computedItems" />

      <div class="row mt-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="card-title"> Organization Overview</div>
              <div class="card-text mt-4">
                <!-- Active Organization -->
                <b-tabs content-class="p-2">
                  <b-tab lazy>
                    <template v-slot:title>
                      <span class="d-none d-sm-inline-block">
                        <i class='bx bx-home' style="font-size: medium;"></i> Organizations
                        <span class="badge bg-primary rounded-pill text-white ml-1"> {{ organizationSummary.numberOfActiveCompanies }} </span>
                      </span>
                    </template>
                    <ActiveOrganizations />
                  </b-tab>

                  <!-- New Registrations -->
                  <b-tab lazy>
                    <template v-slot:title>
                      <span class="d-none d-sm-inline-block">
                        <i class='bx bx-home' style="font-size: medium;"></i> New Registrations
                        <span class="badge bg-success rounded-pill text-white ml-1" v-if="organizationSummary.numberOfNewCompanies > 0"> {{ organizationSummary.numberOfNewCompanies }} </span>
                      </span>
                    </template>
                    <NewOrganizations />
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- No Access -->
    <div
      class="row pt-5"
      v-if="!isPageLoading && loggedInUser.accessLevel !== accessLevelEnum.SUPER_ADMIN"
    >
      <div class="col-md-3"></div>
      <div class="col-md-6 text-center">
        <img src="@/assets/images/no-access.png" alt height="50" />
        <div class="mt-3">
          {{ $t("adminPanel.noPermision") }}
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </Layout>
</template>
