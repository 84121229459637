import { ApiCall } from "../apiBase";
import CustomErrorMessage from "@/shared/util/customErrorMessage.js";

class CreditPlanService {
  /****** Get Company Credit Summary *****/
  async getCompanyCreditSummary(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyCreditSummary($companyId: ID!){
            getCompanyCreditSummary(companyId: $companyId) {
              activeCreditPlanBalance
              activeCreditPlanReceived
              totalBalance
              totalReceived
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getCompanyCreditSummary && !response?.data?.errors) {
      return response?.data?.data?.getCompanyCreditSummary;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }

  }

  /****** Get Company Credit Plans *****/
  async getCompanyCreditPlans(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyCreditPlans($companyId: ID!){
            getCompanyCreditPlans(companyId: $companyId) {
              id
              name
              createdAt
              createdBy
              type
              credits {
                balance
                received
              }
              isExpiring
              isPayPerUsage
              pricePerCredit
              expirationDate
              status
              company
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getCompanyCreditPlans && !response?.data?.errors) {
      return response?.data?.data?.getCompanyCreditPlans;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }

  }
}

export default new CreditPlanService();
