var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card", staticStyle: { height: "610px" } }, [
    _vm.isComponentLoading
      ? _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "card-text" }, [
            _c("div", { staticClass: "row pt-5" }, [
              _c(
                "div",
                { staticClass: "col-md-12 text-center p-5" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    !_vm.isComponentLoading && this.contractDetails
      ? _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "card-text" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "medium",
                      "font-weight": "600",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.contractDetails.name) + " ")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.contractsWidget.contractDetails.startDate"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.formatDate(_vm.contractDetails.startDate)) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.contractsWidget.contractDetails.endDate"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.formatDate(_vm.contractDetails.endDate)) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.contractsWidget.contractDetails.remainingDays"
                      )
                    ) +
                    ": " +
                    _vm._s(
                      _vm.getNumberOfDays(
                        _vm.contractDetails.startDate,
                        _vm.contractDetails.endDate
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "row mt-2" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.contractsWidget.contractDetails.numberOfDataImports"
                      )
                    ) +
                    ": 0 "
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.contractsWidget.contractDetails.numberOfDataRecords"
                      )
                    ) +
                    ": 0 "
                ),
              ]),
            ]),
            _c("hr"),
            _c("div", { staticClass: "row mt-2" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.contractsWidget.contractDetails.createdAt"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.formatDate(_vm.contractDetails.createdAt)) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.contractsWidget.contractDetails.createdBy"
                      )
                    ) +
                    ": " +
                    _vm._s(_vm.contractDetails.createdBy) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "row mt-5" }, [
              _c("div", { staticClass: "col-md-4" }, [
                _c("div", { staticClass: "custom-credit-plan-card" }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "normal",
                        "font-weight": "600",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.contractsWidget.contractDetails.primaryCreditPlan"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "row mt-1" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-center" },
                      [
                        _c("apexchart", {
                          ref: "CreditBalanceChart",
                          staticClass: "apex-charts apexChartData",
                          attrs: {
                            type: "radialBar",
                            height: "250",
                            dir: "ltr",
                            series: this.creditBalancePercentage,
                            options: _vm.creditBalanceChartOptions.chartOptions,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row mt-2 mb-1" }, [
                    _c("div", { staticClass: "col-6 text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.contractsWidget.contractDetails.remaning"
                            )
                          ) +
                          " "
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "normal",
                            "font-weight": "600",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatNumbers(
                                  _vm.contractDetails.primaryCreditPlan.credits
                                    .balance
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "col-6 text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.contractsWidget.contractDetails.received"
                            )
                          ) +
                          " "
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "normal",
                            "font-weight": "600",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatNumbers(
                                  _vm.contractDetails.primaryCreditPlan.credits
                                    .received
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }