<script>
import Vertical from "./vertical";
import AuthService from "@/api/services/auth.service.js";
import GetStartedModal from "@/shared/components/model/get-started-modal.vue";
import NPSModal from "@/shared/components/model/nps-modal.vue";


export default {
  components: { Vertical, GetStartedModal, NPSModal },
  data() {
    return {
      isDisplay: true,
      loggedInUser: null,
      isDisplayGetStartedModal: false,
      isDisplayNPSModal: false
    };
  },
  async mounted() {
    if (window.screen.width < 1024) {
      this.isDisplay = false;
    }

    await this.getLoginUser();
  },
  methods: {
    async getLoginUser() {
      let loggedInUserFromFunction = await AuthService.getLoggedInUser();

      if (loggedInUserFromFunction) {
        this.loggedInUser = loggedInUserFromFunction;
        if (!this.loggedInUser.watchGetStarted)
          this.isDisplayGetStartedModal = true;
        
        if (this.loggedInUser.watchNPS)
          this.isDisplayNPSModal = true;
      }
    }
  }
};
</script>

<template>
  <div>
    <vertical v-if="isDisplay">
      <slot />
    </vertical>
    <div class="text-center mt-5" v-else>
      <img
        src="@/assets/images/onlyMobile.png"
        alt
        class="img-fluid mb-4 mt-3"
        style="width: 350px"
      />
      <h3>Opps!</h3>
      <h5>{{ $t("general.onlyForDesktop") }}</h5>
    </div>

    <!-- Get Strated Modal -->
    <GetStartedModal
      v-if="isDisplayGetStartedModal === true"
      :visible="isDisplayGetStartedModal"
      @close="isDisplayGetStartedModal = false"
      :user="this.loggedInUser"
    />

    <!-- NPS Modal -->
    <NPSModal
      v-if="isDisplayNPSModal === true"
      :visible="isDisplayNPSModal"
      @close="isDisplayNPSModal = false"
      :user="this.loggedInUser"
    />
  </div>
</template>
