import DatafileService from "@/api/services/datafile.service.js";
import i18n from "@/i18n";
import { EMPTY_CELL, INVALID_SCORE } from "@/shared/util/const";
import Languages from "@/shared/util/languages.json";
// import axios from "axios";
import uniq from "lodash.uniq";
import xlsx from "xlsx";
import { ERROR_CODES } from "./errorCodes.js";
import Categories from "./oes-categories.json";
/* eslint-disable no-unused-vars */

/******** Get Error Transaltion Key by Passing Error Code *******/
export const getErrorMessageByErrorCode = (errorCode) => {
  switch (errorCode) {
    case ERROR_CODES.FILE_DOWNLOAD_FAILED:
      return i18n.t("errorCodes.fileDonwloadFailed");
    case ERROR_CODES.FILE_DELETE_FAILED:
      return i18n.t("errorCodes.fileDeleteFailed");
    case ERROR_CODES.VALUES_ARE_ZERO:
      return i18n.t("errorCodes.valuesAreZero");
    case ERROR_CODES.CANNOT_PERFORM_SCORE_CALCULATION:
      return i18n.t("errorCodes.cannotPerformScoreCalculation");
    case ERROR_CODES.CANNOT_MAP_SCORE:
      return i18n.t("errorCodes.cannotMapScore");
    case ERROR_CODES.ANSWER_NOT_ACCESSIBLE:
      return i18n.t("errorCodes.answerNotAccessible");
    case ERROR_CODES.ANSWER_INDEXES_MUST_BE_NUMBERS:
      return i18n.t("errorCodes.answerIndexMustBeNumber");
    case ERROR_CODES.INVALID_ID:
      return i18n.t("errorCodes.invalidID");
    case ERROR_CODES.USER_ALREADY_EXISTING:
      return i18n.t("errorCodes.userAlreadyExisting");
    case ERROR_CODES.EMAIL_SENDING_FAILED:
      return i18n.t("errorCodes.emailSendingFailed");
    case ERROR_CODES.UNAUTHENTICATED:
      return i18n.t("errorCodes.unauthenticated");
    case ERROR_CODES.UNAUTHORIZED:
      return i18n.t("errorCodes.unauthorized");
    case ERROR_CODES.USER_NOT_FOUND:
      return i18n.t("errorCodes.userNotFound");
    case ERROR_CODES.USER_NOT_ACTIVATED:
      return i18n.t("errorCodes.userNotActivated");
    case ERROR_CODES.AUTHENTICATION_FAILED:
      return i18n.t("errorCodes.authenticationFailed");
    case ERROR_CODES.COMPANY_NOT_ACTIVATED:
      return i18n.t("errorCodes.companyNotActivated");
    case ERROR_CODES.COMPANY_DEACTIVATED:
      return i18n.t("errorCodes.companyDeactivated");
    case ERROR_CODES.COMPANY_NOT_FOUND:
      return i18n.t("errorCodes.companyNotFound");
    case ERROR_CODES.RESET_TOKEN_EXPIRED:
      return i18n.t("errorCodes.resetTokenExpired");
    case ERROR_CODES.TOKEN_NOT_FOUND:
      return i18n.t("errorCodes.tokenNotFound");
    case ERROR_CODES.OLD_PASSWORDS_DONT_MATCH:
      return i18n.t("errorCodes.oldPasswordDontMatch");
    case ERROR_CODES.USER_ALREADY_ACTIVATED:
      return i18n.t("errorCodes.userAlreadyActivated");
    case ERROR_CODES.FILEKEY_ALREADY_EXISTS:
      return i18n.t("errorCodes.filekeyAlreadyExist");
    case ERROR_CODES.NO_DATAFILE_FOUND:
      return i18n.t("errorCodes.noDatafileFound");
    case ERROR_CODES.DATAFILE_STRUCTURE_IS_DIFFERENT:
      return i18n.t("errorCodes.datafileStructureIsDifferent");
    case ERROR_CODES.GPT3_NOT_INITIALIZED:
      return i18n.t("errorCodes.gpt3NotInitialized");
    case ERROR_CODES.OES_API_ERROR:
      return i18n.t("errorCodes.oesApiError");
    case ERROR_CODES.UPLOAD_GOING_ON:
      return i18n.t("errorCodes.uploadGoingOn");
    case ERROR_CODES.ITEM_BATTERY_UNDER_MINIMUM:
      return i18n.t("errorCodes.itemBatteryUnderMinimumItems");
    case ERROR_CODES.ITEMS_NON_NUMERIC:
      return i18n.t("errorCodes.itemsIncludsNonNumericValue");
    case ERROR_CODES.ITEM_COLUMN_EMPTY:
      return i18n.t("errorCodes.itemsColumnIsEmptyAndItemBatteryUnderMinimumItems");
    case ERROR_CODES.DRID_EMPTY_COLUMN:
      return i18n.t("errorCodes.idColumnContainsNoData");
    case ERROR_CODES.DRID_ONE_OR_MORE_VALUES_EMPTY:
      return i18n.t("errorCodes.idColumnContainsEmptyValues");
    case ERROR_CODES.DRID_DUPLICATES_FOUND:
      return i18n.t("errorCodes.idColumnContainsDuplicateValues");
    case ERROR_CODES.DRID_MISSING_VALUES:
      return i18n.t("errorCodes.idColumnContainsMissingValues");
    case ERROR_CODES.TIMESTAMP_COLUMN_EMPTY:
      return i18n.t("errorCodes.timestampColumnContainsNoData");
    case ERROR_CODES.TIMESTAMP_NON_NUMERIC:
      return i18n.t("errorCodes.timestampColumnIncludsNonNumericValue");
    case ERROR_CODES.GROUPING_COLUMN_EMPTY:
      return i18n.t("errorCodes.groupingColumnContainsNoData");
    case ERROR_CODES.OPEN_ENED_ANSWER_COLUMN_EMPTY:
      return i18n.t("errorCodes.openEndedAnswerColumnContainsNoData");
    case ERROR_CODES.OPEN_ENED_ANSWER_CONTAINS_ONLY_NUMBERS:
      return i18n.t("errorCodes.openEndedAnswerColumnContainsOnlyNumbers");
    case ERROR_CODES.VOTE_COLUMN_EMPTY:
        return i18n.t("errorCodes.voteColumnContainsNoData");
    case ERROR_CODES.VOTE_NON_NUMERIC:
      return i18n.t("errorCodes.voteColumnIncludsNonNumericValue");
    case ERROR_CODES.PREDICTION_COLUMN_EMPTY:
      return i18n.t("errorCodes.predictionColumnContainsNoData");
    case ERROR_CODES.PREDICTION_NON_NUMERIC:
      return i18n.t("errorCodes.predictionColumnIncludsNonNumericValue");
    case ERROR_CODES.SERVER_ERROR:
        return i18n.t("errorCodes.serverError");
    default:
      return i18n.t("errorCodes.generalError");
  }
};

export const mapOESCategories = (category) => {
  let type, catName;
  let categoryObj = Categories.find((c) => c.code == category);
  if (categoryObj && Object.keys(categoryObj).includes("name")) {
    catName = categoryObj["name"];
  }
  if (catName) {
    type = catName;
  } else {
    type = "-";
  }
  return type;
};

export const mapOESNamesToCodes = (name) => {
  let codeNum, catCode;
  let categoryObj = Categories.find((c) => c.name == name);
  if (categoryObj && Object.keys(categoryObj).includes("code")) {
    catCode = categoryObj["code"];
  }
  if (catCode) {
    codeNum = catCode;
  } else {
    codeNum = -3;
  }
  return codeNum;
};

const SECONDS_PER_UNIT = {
  s: 1,
  m: 60,
  h: 3600,
  d: 86400,
  w: 604800
};

export const convertToSeconds = (val) => {
  return Number.parseInt(val.slice(0, -1)) * SECONDS_PER_UNIT[val.slice(-1)[0]];
};

export const range0to100 = (val) => {
  if (!val || val === undefined) return 0;
  if (val > 100) return 100;
  if (val < 0) return 0;
  return val;
};

/******* Decode key from mongoDB ********/
export const decodeKey = (key) => {
  return key
    .replace(/\\u002e/g, ".")
    .replace(/\\u0024/g, "$")
    .replace(/\\\\/g, "\\");
};

export const exportWorksheet = async function (event, instance) {
  let WorkBook;
  if (event.workbook) WorkBook = JSON.parse(event.workbook);
  var fileName = instance.datafileTitle.concat("- Redem Export Worksheet.xlsx");
  const XLSX = xlsx;
  var wb = XLSX.utils.book_new();
  wb.Props = {
    Title: instance.datafileTitle.concat(" - Redem Export Worksheet"),
    CreatedDate: new Date()
  };
  wb.SheetNames.push("Redem Export Result");
  const ExportFile = event.worksheet;
  let headers = ExportFile.headers;
  var arrayOfArrays = [headers];
  for (const row of ExportFile.rows) {
    let rowRevamped = [];
    for (const col of row) {
      if (col.Text) {
        const text = col.Text === EMPTY_CELL ? "" : col.Text;
        rowRevamped.push(text);
      } else {
        const val = col.Number === INVALID_SCORE ? "" : col.Number;
        rowRevamped.push(val);
      }
    }
    arrayOfArrays.push(rowRevamped);
  }
  var ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);
  wb.Sheets["Redem Export Result"] = ws;
  // Add all existing sheets
  if (WorkBook) {
    for (let i = 1; i < WorkBook.SheetNames.length; i++) {
      wb.SheetNames.push(WorkBook.SheetNames[i]);
      wb.Sheets[WorkBook.SheetNames[i]] =
        WorkBook.Sheets[WorkBook.SheetNames[i]];
    }
  }
  // Add Quality Mark Image on Additional Sheet
  let hasReport = event.certificateCode && event.reportURL;
  if (hasReport) {
    let scoreThresholds = "";
    for (const scoreKey of Object.keys(event.scoreThresholds)) {
      const score = event.scoreThresholds[scoreKey];
      if (score || score === 0) {
        if (scoreThresholds === "") {
          scoreThresholds = `${scoreKey}: ${score}`;
        } else {
          scoreThresholds = `${scoreThresholds}, ${scoreKey}: ${score}`;
        }
      }
    }
    const reportObj = [
      {
        A: this.$t("datafileReport.exportWorksheet.certificateCode"),
        B: event.certificateCode
      },
      {
        A: this.$t("datafileReport.exportWorksheet.reportURL"),
        B: event.reportURL
      },
      {
        A: this.$t("datafileReport.exportWorksheet.certificateLevel"),
        B: event.certificateLevel
      },
      {
        A: this.$t("datafileReport.exportWorksheet.includedRecords"),
        B: event.includedRecords
      },
      {
        A: this.$t("datafileReport.exportWorksheet.removedRecords"),
        B: event.removedRecords
      },
      {
        A: this.$t("datafileReport.exportWorksheet.scoreThresholds"),
        B: scoreThresholds
      }
    ];
    const reportSheet = XLSX.utils.json_to_sheet(reportObj, {
      header: ["A", "B"],
      skipHeader: true
    });
    wb.SheetNames.push("Redem Quality Mark");
    wb.Sheets["Redem Quality Mark"] = reportSheet;
  }
  XLSX.writeFile(wb, fileName);
  instance.closeExportWorksheetModal();
};


export const hasDuplicates=(a)=> {
  return uniq(a).length !== a.length;
}

export const delayFunc = async function (ms) {
  return new Promise(function (resolve) {
    setTimeout(() => resolve(), ms);
  });
};

export const checkDatafileSize = async function (instance, cleanedValues) {
  return new Promise(function (resolve) {
    DatafileService.getNumberOfDatarecords(
      instance.datafileId,
      cleanedValues
    ).then((numberOfRecords) => {
      if (numberOfRecords > 3000 && numberOfRecords <= 7000) {
        // console.log(`[cleaned-results.vue]: Large datafile with ${numberOfRecords} records. Increasing update interval ...`);
        instance.websocketUpdateInterval = 5000;
        instance.maxRequestTime = 5000;
      } else if (numberOfRecords > 7000) {
        // console.log(`[cleaned-results.vue]: Very large datafile with ${numberOfRecords} records. Increasing update interval ...`);
        instance.websocketUpdateInterval = 10000;
        instance.maxRequestTime = 7000;
      }
      resolve();
    });
  });
};

export const initializeSocketBufferTimer = async (instance) => {
  // Max 1 update per X seconds
  while (instance.socket) {
    await delayFunc(instance.websocketUpdateInterval).then(() => {
      if (instance.websocketBuffer > 0) instance.websocketBuffer--;
    });
  }
};

export const isEmptyString = function (string) {
  return (
    string === "" ||
    String(string).trim() === "" ||
    string === undefined ||
    string === null
  );
};

/******* Make GPT3 Modal Avaialble for Use *******/
// export const pingGpt3 = async function (ntimes) {
//   let res;
//   let gpt3ModelLoaded = false;
//   let t = 0;
//   while (!gpt3ModelLoaded && t < ntimes) {
//     try {
//       res = await axios.get(`${process.env.VUE_APP_OES_API_SERVER}/ping/gpt3`);
//     } catch (e) {
//       console.log("Ping GPT-3 failed: ", e);
//     }
//     gpt3ModelLoaded =
//       res?.data?.msg === "GPT-3 initialized without error" ? true : false;
//     if (gpt3ModelLoaded) {
//       return true;
//     }
//     t++;
//   }
//   return false;
// };

export const getLanaguegName = function (code) {
  for (let i = 0; i < Languages.length; i++) {
    if (Languages[i].code === code) {
      return Languages[i].name;
    }
  }
};

export const onlyNumberAndLetters = (str) => {
  return /^[A-Za-z0-9 _äöüÄÖÜß]*$/.test(str);
}

export const noWhitespaces = (str) => {
  let whitespaces = /\s/;
  return !whitespaces.test(str);
}

export const getNumbersAccoringTolanguage = (number, locale, options = {}) => {
  // Ensure the number of decimal places is always 2
  options.minimumFractionDigits = 0;
  options.maximumFractionDigits = 0;
  
  return new Intl.NumberFormat(locale, options).format(number);
}

export const getCreditsAccoringTolanguage = (number, locale, options = {}) => {
  // Ensure the number of decimal places is always 2
  options.minimumFractionDigits = 2;
  options.maximumFractionDigits = 2;
  
  return new Intl.NumberFormat(locale, options).format(number);
}

export const getCurrencyAccoringTolanguage = (number, locale, options = {}) => {
  // Ensure the number of decimal places is always 2
  options.minimumFractionDigits = 2;
  options.maximumFractionDigits = 2;
  
  return new Intl.NumberFormat(locale, options).format(number);
}

export const getDateAccoringTolanguage = (dateString, locale, options = {}) => { 
  const date = new Date(dateString); 
  return new Intl.DateTimeFormat(locale, options).format(date);
}

export const calculateNumberOfDays = (startDate, endDate) => { 
  const StartDate = new Date(startDate);
  const EndDate = new Date(endDate); 

  // Calculate the time difference in milliseconds
  const timeDifference = EndDate - StartDate;
  
  // Convert the time difference from milliseconds to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  return Math.round(daysDifference)
}
