var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-contract-card" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-8" }, [
        _c(
          "div",
          { staticStyle: { "font-size": "medium", "font-weight": "600" } },
          [_vm._v(" " + _vm._s(_vm.contract.name) + " ")]
        ),
      ]),
      _c("div", { staticClass: "col-4 text-right" }, [
        _c(
          "span",
          {
            staticClass: "badge badge-soft-secondary font-size-12",
            class: {
              "badge-soft-success": `${_vm.contract.status}` === "ACTIVE",
              "badge-soft-danger": `${_vm.contract.status}` === "EXPIRED",
              "badge-soft-warning": `${_vm.contract.status}` === "UPCOMING",
            },
          },
          [
            _vm.contract.status === "ACTIVE"
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractStatus.active"
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.contract.status === "EXPIRED"
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractStatus.upcoming"
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.contract.status === "UPCOMING"
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractStatus.expired"
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-6" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "adminComponents.contractsWidget.contractDetails.startDate"
              )
            ) +
            ": " +
            _vm._s(_vm.formatDate(_vm.contract.startDate)) +
            " "
        ),
      ]),
      _c("div", { staticClass: "col-6" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("adminComponents.contractsWidget.contractDetails.endDate")
            ) +
            ": " +
            _vm._s(_vm.formatDate(_vm.contract.endDate)) +
            " "
        ),
      ]),
    ]),
    _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-6" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("adminComponents.contractsWidget.contractDetails.type")
            ) +
            ": "
        ),
        _c(
          "span",
          { staticClass: "badge badge-soft-primary font-size-12 ml-1" },
          [_vm._v(" " + _vm._s(_vm.contract.type))]
        ),
      ]),
      _c("div", { staticClass: "col-6" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "adminComponents.contractsWidget.contractDetails.pricePerCredit"
              )
            ) +
            ": "
        ),
        _c("i", { staticClass: "bx bx-euro" }),
        _vm._v(
          " " + _vm._s(_vm.formatCurrency(_vm.contract.pricePerCredit)) + " "
        ),
      ]),
    ]),
    _c("hr"),
    _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-6" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("adminComponents.contractsWidget.contractDetails.received")
            ) +
            ": " +
            _vm._s(
              _vm.formatCredit(_vm.contract.primaryCreditPlan.credits.received)
            ) +
            " "
        ),
      ]),
      _c("div", { staticClass: "col-6" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("adminComponents.contractsWidget.contractDetails.remaning")
            ) +
            ": " +
            _vm._s(
              _vm.formatCredit(_vm.contract.primaryCreditPlan.credits.balance)
            ) +
            " "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }