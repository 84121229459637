<script>
import store from "../../../state/store";

export default {
  data() {
    return {};
  },
  computed: {
    showModal: {
      get() {
        return this.checkExpiryConditions();
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },

  methods: {
    updateConsent() {
      store.dispatch("auth/updateExpireAlertConsent");
    },
    checkExpiryConditions() {
      return (
        store.getters["auth/expireAlert"] &&
        !store.getters["auth/expireAlertConsent"]
      );
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="row">
      <div class="col-md-12">
        <h5 class="mb-4">{{ $t("general.expireAlert.title") }}</h5>
        <span>
          {{ $t("general.expireAlert.description.line1") }}
          <br />
          {{ $t("general.expireAlert.description.line2") }}
        </span>
      </div>
      <div class="col-md-12 mt-4">
        <b-button variant="success" @click="updateConsent">Noted</b-button>
      </div>
    </div>
  </b-modal>
</template>
