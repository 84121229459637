import { creditColorDecider } from "../../util/chart-helper";

// Credit Chart
export function CreditBalanceChart() {
  this.chartOptions = {
    chart: {
      height: 250,
      type: "radialBar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front"
        },
        track: {
          background: "#f5f5f5",
          strokeWidth: "80%",
          margin: 0 // margin is in pixels
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function(val) {
              return parseInt(val) + "%";
            },
            color: "#111",
            fontSize: "30px",
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function({ value }) {
        return creditColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    }
  };
}