<script>
import CompanyService from "@/api/services/company.service.js";
import { getErrorMessageByErrorCode, getCreditsAccoringTolanguage } from "@/shared/util/helpers.js";

export default {
  data() {
    return {
      /***** Data variables *****/
      organizations: null,
      dataForTable: null,
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false,
      /***** Table Data *****/
      fieldsForTable: [
        {
          key: "id",
          label: "ID",
          thClass: "d-none",
          tdClass: "d-none"
        },
        {
          key: "createdDate",
          label: "Created Date",
          thClass: "d-none",
          tdClass: "d-none"
        },
        {
          key: "organization",
          label: "Organization",
          sortable: true,
          thStyle: { width: "250px" }
        },
        {
          key: "owner",
          label: "Owner",
        },
        {
          key: "wallet",
          label: "Wallet Balance",
        },
        {
          key: "action",
          label: ""
        },
      ],
      currentPage: 1,
      perPage: 5,
      filter: null
    }
  },
  computed: {
    rows() {
      return this.organizations.length;
    },
  },
  async mounted() {
    this.isComponentLoading = true;
    await this.fetchActiveOrganizations();
    this.isComponentLoading = false;
  },
  methods: {
    async fetchActiveOrganizations() {
      try {
        this.dataForTable = []
        this.organizations   = await CompanyService.getAllActiveCompanies();

        // inster data into table
        this.organizations.forEach(organization => {
          let organizationToPush = {
            id: organization.id,
            organization: {
              name: organization.name,
              image: organization.image
            },
            owner: {
              id: organization.companyOwner.id,
              name: organization.companyOwner.name.first + ' ' + organization.companyOwner.name.last,
              email: organization.companyOwner.email,
              image: organization.companyOwner.image,
            },
            wallet: organization.wallet
          }

          this.dataForTable.push(organizationToPush)
        });
        
      } catch (error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    formatNumbers(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");

      return getCreditsAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
    clickOrganizationinfo(organization) {
      this.$router.push({
        path: "/organization-details",
        query: { organization: organization.id }
      })
    }
  }
}
</script>

<template>
  <div>

    <!-- Component Loading -->
    <div
      class="row pt-5"
      v-if="isComponentLoading"
    >
      <div class="col-md-12 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Display active organizations in Table -->
    <div v-if="!isComponentLoading && organizations && organizations.length > 0" class="mt-4">

      <!-- Search Bar -->
      <div class="row mb-4">
        <div class="col-md-3">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Search"
          ></b-form-input>
        </div>
      </div>

      <!-- Data Table -->
      <div class="table-responsive mb-0">
        <b-table
          :items="dataForTable"
          :fields="fieldsForTable"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :bordered="true"
          :filter="filter"
          :filter-included-fields="['organization']"
        >
          <!-- Organization Column -->
          <template v-slot:cell(organization)="data">
            <div class="row align-items-center">
              <div class="col-md-12">
                <img
                  :src="data.value.image"
                  class="rounded-circle"
                  style="height: 30px"
                />
                <span style="font-weight: 600"> {{ data.value.name }} </span>
              </div>
            </div>
          </template>

          <!-- Organization Owner -->
          <template v-slot:cell(owner)="data">
            <div class="row align-items-center justify-content-between">
              <div class="col-1">
                <img
                  :src="data.value.image"
                  class="rounded-circle"
                  style="height: 30px"
                />
              </div>
              <div class="col-11">
                <div style="font-weight: 600"> {{ data.value.name }} </div>
                <small> {{ data.value.email }} </small>
              </div>
            </div>
          </template>

          <!-- Organization Wallet -->
          <template v-slot:cell(wallet)="data">
            {{  formatNumbers(data.value.balance) }}
          </template>

          <!-- Action -->
          <template v-slot:cell(action)="scope">
            <i style="font-size: 15px; cursor: pointer;" class="bx bx-info-circle" @click="clickOrganizationinfo(scope.item)"></i>
          </template>
        </b-table>
      </div>

      <!-- pagination -->
      <div class="row">
        <div class="col">
          <div
            class="
              dataTables_paginate
              paging_simple_numbers
              float-right
            "
          >
            <ul class="pagination pagination-rounded mb-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>