<script>
import { required } from "vuelidate/lib/validators";
import AccessLevels from "@/shared/enums/aceessLevels";
import axios from "axios";
import UserService from "@/api/services/user.service.js";
import AuthService from "@/api/services/auth.service.js";
import UserImageCropperModal from "@/shared/components/model/user-avatar-cropper-modal.vue";
import ResponseStatus from "@/shared/enums/responseStatus";
import { EventBus } from "@/shared/util/event-bus.js";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    user: Object,
    isEditProfile: Boolean,
    organizationOwner: Object
  },
  components: {
    UserImageCropperModal
  },
  data() {
    return {
      /******** Data Variables ******/
      firstName: this.user.name.first,
      lastName: this.user.name.last,
      adminAccess: this.user.accessLevel === AccessLevels.ADMIN ? true : false,
      userImageURL: this.user.image,
      selectedUserImageFile: null,
      croppedUserImageFile: null,
      /******** Operational Variables *******/
      isUpdateUserSuccess: false,
      isSendResetPasswordLinkSuccess: false,
      isUpdateUserError: false,
      updateUserErrorMessage: null,
      displayImageCropperModal: false
    };
  },
  validations: {
    firstName: { required },
    lastName: { required },
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    clickSave() {
      // stop here if form is invalid
      this.$v.$touch();

      if(this.$v.$invalid) {
        this.isUpdateUserError = true;
        this.updateUserErrorMessage = this.$i18n.t("adminComponents.editUserOverlay.validationError");
      } else {
        this.updateUser();
      }
    },
    userAvatarSelected() {
      const imagePreviewCanvers = this.$refs.userAvatarImageCanvas;
      const imagePreviewCanversContext = imagePreviewCanvers.getContext("2d");

      imagePreviewCanversContext.clearRect( 0, 0, imagePreviewCanvers.width, imagePreviewCanvers.height);

      const avatarImageFile = this.$refs.userAvatarInput.files[0];
      this.openImageCropperModal(avatarImageFile);
    },
    openImageCropperModal(avatarImageFile) {
      this.selectedUserImageFile = avatarImageFile;
      this.displayImageCropperModal = true;
    },
    async setCroppedUserImagePreview(croppedImage) {
      const imagePreview = this.$refs.userAvatarImageCanvas;
      const imagePreviewCanvers = this.$refs.userAvatarImageCanvas;
      if (imagePreview && imagePreviewCanvers.getContext) {
        const imagePreviewCanversContext = imagePreviewCanvers.getContext("2d");
        imagePreviewCanversContext.drawImage(croppedImage, 0, 0, 65, 65);
      }
      this.croppedUserImageFile = croppedImage;
      this.displayImageCropperModal = false;

      const preSignedURL = await UserService.getUserAvatarPreSignedURL(
        this.selectedUserImageFile.name,
        this.user.id
      );

      if (preSignedURL) {
        const croppedUserImage = this.croppedUserImageFile;
        const originalUserImageFile = this.selectedUserImageFile;
        const userImageFile = await new Promise(function(resolve) {
          return croppedUserImage.toBlob(
            resolve,
            originalUserImageFile.type
          );
        });

        // Upload Image to S3
        const getFileUploadResponse = await axios.put(
          preSignedURL.signedUrl,
          userImageFile
        );

        // is axios.put work assign file name to file URL
        if (getFileUploadResponse) {
          const fullImageURL = getFileUploadResponse.config.url;
          const splitedtImageURL = fullImageURL.split("/profile_avatars")[0];
          this.userImageURL =
            splitedtImageURL +
            "/profile_avatars/" +
            this.user.id +
            "/" +
            originalUserImageFile.name;
        }
      }
    },
    async updateUser() {
      const updateUserObject = {
        userId: this.user.id,
        name: {
          first: this.firstName,
          last: this.lastName,
        },
        image: this.userImageURL,
        accessLevel: this.adminAccess === true ? AccessLevels.ADMIN : AccessLevels.EMPLOYEE
      }

      try {
        let response = await UserService.updateUser(updateUserObject);
        if (response.status === ResponseStatus.SUCCESS) {
          this.isUpdateUserSuccess = true;
          setTimeout(() => {
            this.isUpdateUserSuccess = false;
            this.$emit("close");
            EventBus.$emit("refreshUserManagementTab");
          }, 2000);
        }
      } catch (error) {
        this.isUpdateUserError = true;
        this.updateUserErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    async clickResetPassword() {
      try {
        let response = await AuthService.sendPasswordResetEmail(this.user.email);
        if (response.status === ResponseStatus.SUCCESS) {
          this.isSendResetPasswordLinkSuccess = true;
          setTimeout(() => {
            this.isSendResetPasswordLinkSuccess = false;
            this.$emit("close");
          }, 2000);
        }
      } catch (error) {
        this.isUpdateUserError = true;
        this.updateUserErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    copyAPIKeyToClipboard() {
      try {
        navigator.clipboard.writeText(this.user.magicKey);
        this.magicKeyCopySuccess = true;
      } catch (err) {
        this.magicKeyCopyFail = true;
        throw err;
      }
    },
    clickDeleteUser() {
      this.$emit("close");
      EventBus.$emit("deleteActiveUser", this.user);
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    size="md"
  >
    <!-- Title -->
    <div class="row d-flex align-items-center">
      <div class="col-md-11">
        <div style="font-size: medium; font-weight: 600"> {{$t("adminComponents.editUserOverlay.title")}} </div>
      </div>
      <div class="col-md-1 text-center">
        <i class='bx bx-x' style="font-size: x-large; cursor: pointer;" @click="clickClose"></i>
      </div>
    </div>

    <!-- Form -->
    <div class="mt-4">
      <!-- Error Message -->
      <b-alert
        v-model="isUpdateUserError"
        class="mt-3 mb-3"
        variant="danger"
        dismissible
        >{{ updateUserErrorMessage }}</b-alert
      >

      <!-- Success Message -->
      <b-alert
        v-model="isUpdateUserSuccess"
        class="mt-3 mb-3"
        variant="success"
      >
        <i class='bx bx-check-double bx-tada mr-1' ></i> {{$t("adminComponents.editUserOverlay.updateSuccess")}}
      </b-alert>

      <!-- Send Password Reset Link - Success Message -->
      <b-alert
        v-model="isSendResetPasswordLinkSuccess"
        class="mt-3 mb-3"
        variant="success"
      >
        <i class='bx bx-check-double bx-tada mr-1' ></i> {{$t("adminComponents.editUserOverlay.sendPasswordResetLinkSuccess")}}
      </b-alert>

      <div class="media">
        <!-- Display Image Section -->
        <div class="avatar-md mr-4">
          <span
            ref="userAvatarImagePreview"
            class="avatar-preview-after-crop"
          >
            <canvas
              id="userAvatarImageCanvas"
              ref="userAvatarImageCanvas"
              height="65"
              width="65"
              style="border-radius: 50%"
            ></canvas>
            <span
              v-if="!croppedUserImageFile"
              class="avatar-title bg-white"
              style="margin-top: -70px"
            >
              <img
                v-if="userImageURL"
                :src="userImageURL"
                alt
                class="rounded-circle"
                height="65"
              />
              <img
                v-else
                src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png"
                alt
                class="rounded-circle"
                height="65"
              />
            </span>
          </span>
        </div>
        <!-- Image Input Section -->
        <div class="media-body align-self-center">
          <div class="text-muted">
            <p class="mb-1">
              <input
                id="userAvatarInput"
                ref="userAvatarInput"
                type="file"
                name="file"
                accept="image/*"
                @change="userAvatarSelected()"
                style="width: 0.1px; opacity: 0; z-index: -1"
              />
              <label
                for="userAvatarInput"
                class="text-info mr-2"
                style="cursor: pointer"
              >
              {{$t("adminComponents.editUserOverlay.image.label")}}
              </label>
            </p>
            <p class="mb-1">
              {{$t("adminComponents.editUserOverlay.image.description")}}
            </p>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-6">
          <b-form-group id="first-name-group" label-for="firstName">
            <template v-slot:label>
              {{$t("adminComponents.editUserOverlay.form.firstName.label")}}
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="firstName"
              v-model="firstName"
              type="text"
              :placeholder="$t('adminComponents.editUserOverlay.form.firstName.placeholder')"
              :class="{ 'is-invalid':  $v.firstName.$error }"
            ></b-form-input>
            <div v-if="$v.firstName.$error" class="invalid-feedback">
              <span v-if="!$v.firstName.required">{{$t("adminComponents.editUserOverlay.form.firstName.required")}}</span>
            </div>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group id="last-name-group" label-for="lastName">
            <template v-slot:label>
              {{$t("adminComponents.editUserOverlay.form.lastName.label")}}
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="lastName"
              v-model="lastName"
              type="text"
              :placeholder="$t('adminComponents.editUserOverlay.form.lastName.placeholder')"
              :class="{ 'is-invalid': $v.lastName.$error }"
            ></b-form-input>
            <div v-if="$v.lastName.$error" class="invalid-feedback">
              <span v-if="!$v.lastName.required">{{$t("adminComponents.editUserOverlay.form.lastName.required")}}</span>
            </div>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <label> {{$t("adminComponents.editUserOverlay.form.APIKey")}} </label>
          
          <div class="input-group bg-light rounded">
            <input
              type="text"
              class="form-control bg-transparent border-0"
              v-model="user.magicKey"
              disabled
            />

            <button
              class="btn btn-primary"
              type="button"
              id="button-addon2"
              @click="copyAPIKeyToClipboard()"
            >
              <i class="bx bx-copy-alt"></i>
            </button>
          </div>
        </div>

      </div>

      <div class="row mt-3" v-if="!this.isEditProfile">
        <div class="col-md-12">
          <b-form-checkbox
            class="mt-2"
            v-model="adminAccess"
            name="check-button"
            switch
          >
            {{$t("adminComponents.editUserOverlay.form.adminAccess")}}
          </b-form-checkbox>
        </div>
      </div>

    </div>

    <!-- Actions -->
    <div class="row mt-5">
      <div class="col-md-12">
        <b-button variant="primary" @click="clickSave">
          {{$t("adminComponents.editUserOverlay.saveButton")}}
        </b-button>
        <b-button variant="outline-primary" class="ml-2" @click="clickResetPassword" v-if="!this.isEditProfile">
          {{$t("adminComponents.editUserOverlay.passwordResetButton")}}
        </b-button>
        <b-button variant="light" class="ml-2" v-if="!this.isEditProfile && (this.user.id !== this.organizationOwner.id)" @click="clickDeleteUser">
          {{$t("adminComponents.editUserOverlay.deleteButton")}}
        </b-button>
      </div>
    </div>

    <!-- Organization Image Cropper Modal -->
    <UserImageCropperModal
      v-if="displayImageCropperModal"
      :visible="displayImageCropperModal"
      :imageFile="selectedUserImageFile"
      @close="displayImageCropperModal = false"
      @apply="setCroppedUserImagePreview"
    />
  </b-modal>
</template>
