<script>
import ContractDetailCard from './contract-detail-card.vue';
import OrganizationContractsCard from './organization-contracts-card.vue';
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    organization: Object
  },
  components: {
    OrganizationContractsCard,
    ContractDetailCard
  },
  data() {
    return {
      /***** Data variables *****/
      selectedContract: this.organization.contract,
      numberOfComponentsShouldGetLoaded: 2,
      numberOfComponentsGetLoaded: 0,
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false
    }
  },
  created() {
    EventBus.$on("contractComponetGetLoaded", async () => {
      this.numberOfComponentsGetLoaded += 1

      if(this.numberOfComponentsShouldGetLoaded === this.numberOfComponentsGetLoaded) {
        this.isComponentLoading = false;
      }
    });

    EventBus.$on("chanageSelectedContractToDisplayDetails", async (contractToDisplayDetails) => {
      this.selectedContract = contractToDisplayDetails;
    })
  }
}
</script>

<template>
  <div>
    <!-- Component Loading -->
    <div
      class="row pt-5"
      v-if="isComponentLoading"
    >
      <div class="col-md-12 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Display Data -->
    <div v-if="!isComponentLoading" class="animate__animated animate__fadeInUp animate__slow">
      <div class="row mt-3">
        <!-- All Contracts -->
        <div class="col-md-4">
          <OrganizationContractsCard :organization="organization" />
        </div>

        <!-- Contract Detail -->
        <div class="col-md-8">
          <ContractDetailCard :contract="this.selectedContract"/>
        </div>
      </div>
    </div>

    <!-- Display Error -->
    <div class="row pt-5" v-if="!isComponentLoading && isFetchingDataError">
      <div class="col-md-12 text-center">
        {{ this.dataFetchingnErrorMessage }}
      </div>
    </div>
  </div>
</template>
