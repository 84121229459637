var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card", staticStyle: { height: "610px" } }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "card-text" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          _c("div", { staticClass: "col-4" }, [
            _c(
              "span",
              { staticStyle: { "font-size": "medium", "font-weight": "600" } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("adminComponents.contractsWidget.title")) +
                    " "
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "col-8 text-right" },
            [
              _c(
                "b-form-checkbox",
                {
                  staticStyle: {
                    display: "inline-block",
                    "margin-right": "10px",
                    "text-align": "right",
                  },
                  on: { change: _vm.selectAllReceivedTransactions },
                  model: {
                    value: _vm.showAllActiveContract,
                    callback: function ($$v) {
                      _vm.showAllActiveContract = $$v
                    },
                    expression: "showAllActiveContract",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractStatus.active"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "b-form-checkbox",
                {
                  staticStyle: {
                    display: "inline-block",
                    "margin-right": "10px",
                    "text-align": "right",
                  },
                  on: { change: _vm.selectAllUsedTransactions },
                  model: {
                    value: _vm.showAllUpcomingContracts,
                    callback: function ($$v) {
                      _vm.showAllUpcomingContracts = $$v
                    },
                    expression: "showAllUpcomingContracts",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractStatus.upcoming"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "b-form-checkbox",
                {
                  staticStyle: {
                    display: "inline-block",
                    "margin-right": "10px",
                    "text-align": "right",
                  },
                  on: { change: _vm.selectAllUsedTransactions },
                  model: {
                    value: _vm.showAllExpiredContracts,
                    callback: function ($$v) {
                      _vm.showAllExpiredContracts = $$v
                    },
                    expression: "showAllExpiredContracts",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractStatus.expired"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "row mt-3" },
          _vm._l(_vm.contractsToDisplay, function (contract, contractIndex) {
            return _c(
              "div",
              {
                key: contractIndex,
                staticClass: "col-12 mb-2",
                on: {
                  click: function ($event) {
                    return _vm.selectContractToDisplayDetails(contract)
                  },
                },
              },
              [_c("ContractCard", { attrs: { contract: contract } })],
              1
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }