var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isComponentLoading
      ? _c("div", { staticClass: "row pt-5" }, [
          _c(
            "div",
            { staticClass: "col-md-12 text-center p-5" },
            [
              _c("b-spinner", {
                staticClass: "m-2",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "mid", role: "status" },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isComponentLoading && !_vm.isFetchingDataError,
            expression: "!isComponentLoading && !isFetchingDataError",
          },
        ],
        staticClass:
          "row mt-3 animate__animated animate__fadeInUp animate__slow",
      },
      [
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c("OrganizationWalletCard", {
              attrs: { organization: _vm.organization },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-9" },
          [
            _c("OrganizationTransactionCard", {
              attrs: { organization: _vm.organization },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }