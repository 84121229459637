import { CREDIT_RANGES, TRAFFIC_LIGHT_RANGES } from "../../shared/util/const";

const trafficLightColorDecider = value => {
  if (value >= TRAFFIC_LIGHT_RANGES.GREEN_START) {
    return "#34c38f";
  } else if (value >= TRAFFIC_LIGHT_RANGES.LIGHT_GREEN_START) {
    return "#a5e79a";
  } else if (value >= TRAFFIC_LIGHT_RANGES.YELLOW_START) {
    return "#ffcc44";
  } else if (value >= TRAFFIC_LIGHT_RANGES.RED_START) {
    return "#f46a6a";
  }
};

const creditColorDecider = value => {
  if (value >= CREDIT_RANGES.GREEN_START) {
    return "#34c38f";
  } else if (value >= CREDIT_RANGES.YELLOW_START) {
    return "#ffcc44";
  } else if (value >= CREDIT_RANGES.RED_START) {
    return "#f46a6a";
  }
};

const trafficLightClassDecider = value => {
  if (value >= TRAFFIC_LIGHT_RANGES.GREEN_START) {
    return "text-success";
  } else if (value >= TRAFFIC_LIGHT_RANGES.LIGHT_GREEN_START) {
    return "text-average";
  } else if (value >= TRAFFIC_LIGHT_RANGES.YELLOW_START) {
    return "text-warning";
  } else if (value >= TRAFFIC_LIGHT_RANGES.RED_START) {
    return "text-danger";
  }
  return "";
};

export { trafficLightColorDecider, trafficLightClassDecider, creditColorDecider };
