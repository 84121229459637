import { ApiCall } from "../apiBase";
import CustomErrorMessage from "@/shared/util/customErrorMessage.js";

class ContractService {
  async updateTestPlan(contractInput) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
        mutation updateTestPlan($input: updateTestPlanInput!) {
          updateTestPlan (input: $input) {
           status
           message
          }
        }
      `,
        variables: {
          input: contractInput
        }
      }
    });

    if (result.data.data.updateTestPlan.status === "SUCCESS") {
      return true;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  /************* get All Contracts for Compnay*********/
  async getCompanyContracts(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyContracts($companyId: ID!){
            getCompanyContracts(companyId: $companyId) {
              id
              name
              createdAt
              createdBy
              startDate
              endDate
              pricePerCredit
              status
              assignedCompany
              primaryCreditPlan {
                id
                name
                createdAt
                createdBy
                type
                credits {
                  balance
                  received
                }
                isExpiring
                isPayPerUsage
                pricePerCredit
                expirationDate
                status
                company
              }
              type
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getCompanyContracts && !response?.data?.errors) {
      return response?.data?.data?.getCompanyContracts;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* Get Contract by ID *********/
  async getContractDetails(contractId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getContractById($contractId: ID!){
            getContractById(contractId: $contractId) {
              id
              name
              createdAt
              createdBy
              startDate
              endDate
              pricePerCredit
              status
              assignedCompany
              primaryCreditPlan {
                id
                name
                createdAt
                createdBy
                type
                credits {
                  balance
                  received
                }
                isExpiring
                isPayPerUsage
                pricePerCredit
                expirationDate
                status
                company
              }
              type
            }
          }
        `,
        variables: {
          contractId: contractId
        }
      }
    });

    if (response?.data?.data?.getContractById && !response?.data?.errors) {
      return response?.data?.data?.getContractById;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }
}

export default new ContractService();
