<script>
import UserService from "@/api/services/user.service.js";

const TYPEFORM_URL = "https://zbzcp2u0rvn.typeform.com/to/MT4A9bSH";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    user: Object
  },
  data() {
    return {
      /******** Data Variables ******/
      /******** Operational Variables *******/
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    async clickYes() {
      const response = await UserService.updateNPS(this.user.id);
      if (response === "SUCCESS") {
        window
          .open(
            TYPEFORM_URL,
            "_blank"
          )
          .focus();
        this.$emit("close");
      }
    },
    async clickNo() {
      const response = await UserService.updateNPS(this.user.id);
      if (response === "SUCCESS") {
        this.$emit("close");
      }
    },
    async clickLater() {
      //select next time to show the modal after 1 week
      const currentTime = new Date();
      const nextTime = new Date(currentTime.getTime() + 7 * 24 * 60 * 60 * 1000);
      const nextNPSDate = nextTime.toISOString();
      const response = await UserService.updateNPS(this.user.id, nextNPSDate);
      if (response === "SUCCESS") {
        this.$emit("close");
      }
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="col-lg-12 text-right  mt-2">
        <b-button variant="light" @click="clickLater()">{{
          $t("general.npsModal.button.later")
        }}</b-button>
    </div>
    <div class="row text-center">
      <div class="col-lg-12 mt-3">
        <img
          src="@/assets/images/nps-image.jpg"
          alt
          class="img-fluid mb-4 mt-3"
          style="width: 250px"
        />
        <h5>{{ $t("general.npsModal.title") }}</h5>
        <p>
          {{ $t("general.npsModal.descrption") }}
        </p>
      </div>
      <div class="p-3 col-md-12 text-lg-center">
        <b-button 
        style="width: 200px"
        variant="success" @click="clickYes()">{{
          $t("general.npsModal.button.yes")
        }}</b-button>
    
        

      </div>
    </div>
  </b-modal>
</template>
