import { ApiCall } from "../apiBase";

class DatafileService {
  async getDatafileTitle(datafileId) {
    let datafile = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDatafile($datafileId: ID!){
            getDatafile(datafileId: $datafileId) {
              title
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (result?.data?.data?.getDatafile && !result?.data?.errors) {
      datafile = result.data.data.getDatafile;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
    return datafile.title;
  }

  async getDatafile(datafileId) {
    let datafile = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDatafile($datafileId: ID!){
            getDatafile(datafileId: $datafileId) {
              id
              title
              isLiveCleaning
              datafileKey
              isWeighted
              includeTimestamp
              includeItemBattery
              includeCS
              includePS
              includeOES
              certificateLevel
              integration
              metadata {
                dataRecordIdColumn
                headerRow
                dataRecordStartRow
                missingValues
                datafileSecretKey
              }
              cleaningSettings {
                scoreThresholds {
                  key
                  name
                  isEnable
                  threshold
                }
                targetSampleSize
              }
              weightingMethod {
                datafileWeighting {
                  key
                  value
                  name
                }
                projectiveQuestionsWeighting {
                  dataPointIdentifier
                  algorithms {
                    key
                    value
                    name
                  }
                  apply
                }
              }
              cleaningMethod
              isLiveCleaning
              numberOfRecords
              numberOfCleanedRecords
              isCleaned
              markThreshold
              importType
              owner {
                id
                name {
                  first
                  last
                }
              },
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (result?.data?.data?.getDatafile && !result?.data?.errors) {
      datafile = result.data.data.getDatafile;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
    return datafile;
  }

  async getWeightingSettings(datafileId) {
    let datafile = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDatafile($datafileId: ID!){
            getDatafile(datafileId: $datafileId) {
              weightingMethod {
                key
                value
                name
              }
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (result?.data?.data?.getDatafile && !result?.data?.errors) {
      datafile = result.data.data.getDatafile;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }

    return datafile.weightingMethod;
  }

  async getProjectiveQuestions(datafileId) {
    let questions = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getProjectiveQuestions($datafileId: ID!){
            getProjectiveQuestions(datafileId: $datafileId)
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (result?.data?.data?.getProjectiveQuestions && !result?.data?.errors) {
      questions = result.data.data.getProjectiveQuestions;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }

    return questions;
  }

  async getAlgorithmsInDatafile(datafileId) {
    let algorithms = {};
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
        query getAlgorithmsInDatafile($datafileId: ID!){
          getAlgorithmsInDatafile(datafileId: $datafileId) {
            IS
            PS
            SDS
            TS
            IBS
            CS
            OES
          }
        }
      `,
        variables: {
          datafileId
        },
        operationName: "getAlgorithmsInDatafile"
      }
    });
    if (response) {
      algorithms = response.data.data.getAlgorithmsInDatafile;
    }
    return algorithms;
  }

  async getDatafileMetadata(datafileId, isCleaned) {
    let metadata = {};
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDatafileMetadata($datafileId: ID!, $isCleaned: Boolean) {
            getDatafileMetadata(datafileId: $datafileId, isCleaned: $isCleaned) {
              numberOfRecords
              numberOfValidRecords
              numberOfInvalidRecords
              numberOfDatapoints
              numberOfProjectiveQuestions
              numberOfQualityScores
              dateOfCreation
              cost
              createdBy
              title
              isWeighted
              isCleaned
              missingValues
              isEnableWrongTopicCheck
            }
          }
        `,
        variables: {
          datafileId,
          isCleaned
        }
      }
    });
    if (response) {
      metadata = response.data.data.getDatafileMetadata;
      metadata.requestQueue = response.headers["request-queue"];
    }
    return metadata;
  }

  async getNumberOfDatarecords(datafileId, isCleaned) {
    let numberOfRecords = 0;
    const response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getNumberOfDatarecords($datafileId: ID!, $isCleaned: Boolean) {
            getNumberOfDatarecords(datafileId: $datafileId, isCleaned: $isCleaned)
          }
        `,
        variables: {
          datafileId,
          isCleaned
        }
      }
    });
    if (response) {
      numberOfRecords = response.data.data.getNumberOfDatarecords;
    }
    return numberOfRecords;
  }

  /********* Transfer Datafile Ownership **********/
  async transferDatafileOwnership(
    datafileId,
    companyId,
    userId,
    changeWalletAndTransactions
  ) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation changeDatafileOwnership($datafileId: ID!, $companyId: ID!, $userId: ID!, $changeWalletAndTransactions: Boolean!) {
            changeDatafileOwnership(datafileId: $datafileId, companyId: $companyId, userId: $userId, changeWalletAndTransactions: $changeWalletAndTransactions) {
              status
              message
            }
          }
        `,
        variables: {
          datafileId: datafileId,
          companyId: companyId,
          userId: userId,
          changeWalletAndTransactions: changeWalletAndTransactions
        }
      }
    });
    if (result?.data?.data?.changeDatafileOwnership && !result?.data?.errors) {
      return result.data.data.changeDatafileOwnership;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  /******* Create Draft Datafile *****/
  async createDraftDatafile(datafileObject) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation createDraftDatafile($projectInput: DraftDatafileInput!) {
            createDraftDatafile(datafileInput: $projectInput) {
              datafileId
            }
          }
        `,
        variables: {
          projectInput: datafileObject
        }
      }
    });
    if (response?.data?.data?.createDraftDatafile && !response?.data?.errors) {
      return response?.data?.data?.createDraftDatafile.datafileId;
    } else {
      console.log("error here", response?.data?.errors[0]);
      throw Error(response?.data?.errors[0]?.extensions?.code);
    }
  }

  /****** Stop Live Status *****/
  async stopLiveDatafile(datafileID) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation stopLiveDatafile($datafileId: ID!) {
            stopLiveDatafile(datafileId: $datafileId) {
              status
              message
            }
          }
        `,
        variables: {
          datafileId: datafileID
        }
      }
    });
    if (response?.data?.data) {
      return response.data.data.stopLiveDatafile;
    } else {
      throw Error(response?.data?.errors[0]?.extensions?.code);
    }
  }

  /****** Re-Open Live Status *****/
  async reOpenLiveDatafile(datafileID) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation reOpenLiveDatafile($datafileId: ID!) {
            reOpenLiveDatafile(datafileId: $datafileId) {
              status
              message
            }
          }
        `,
        variables: {
          datafileId: datafileID
        }
      }
    });
    if (response?.data?.data) {
      return response.data.data.reOpenLiveDatafile;
    } else {
      throw Error(response?.data?.errors[0]?.extensions?.code);
    }
  }

  /****** Get Draft Datafile ******/
  async getDraftDatafile(datafileID) {
    let apiCallBody = {
      method: "post",
      data: {
        query: `
          query getDraftedDatafile($datafileId: ID!) {
            getDraftedDatafile(datafileId: $datafileId) {
              id
              name
              createdDate
              company {
                id
                name
                image
              }
              owner {
                id
                name {
                  first
                  last
                }
                image
                email
                magicKey
              }
              integration {
                id
                name
              }
              cleaningSetting {
                targetSampleSize
              }
              datafileSecretKey
              datapointsMetadata {
                dataPointIdentifier
                dataPointType
                questionTitle
                isEnableCopyPasteCheck
                startingQuestion
                algorithms {
                  IS
                  PS
                  SDS
                  TS
                  IBS
                  CS
                  OES
                }
                pageNumber
                pageTitle
              }
            }
          }
          `,
        variables: {
          datafileId: datafileID
        }
      }
    };
    let response = await ApiCall(apiCallBody);
    if (response?.data?.data) {
      return response.data.data.getDraftedDatafile;
    } else {
      throw Error(response?.data?.errors[0]?.extensions?.code);
    }
  }

  /********* Get Datafile Cleaning Settings **********/
  async getDatafileCleaningSettings(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDatafileCleaningSettings($datafileId: ID!){
            getDatafileCleaningSettings(datafileId: $datafileId) {
              cleaningMethod
              cleaningSettings {
                scoreThresholds {
                  key
                  name
                  isEnable
                  threshold
                }
                targetSampleSize
              }
              markThreshold
              isCleaned
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (
      result?.data?.data?.getDatafileCleaningSettings &&
      !result?.data?.errors
    ) {
      return result.data.data.getDatafileCleaningSettings;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  /****** Edit Cleaning Settings *****/
  async editCleaningSettings(datafileID, cleaningSettingsInput) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateCleaningSettings($datafileId: ID!, $cleaningSettings: [ScoreThresholdInput]!) {
            updateCleaningSettings(datafileId: $datafileId, cleaningSettings: $cleaningSettings) {
              status
              message
            }
          }
        `,
        variables: {
          datafileId: datafileID,
          cleaningSettings: cleaningSettingsInput
        }
      }
    });
    if (response?.data?.data) {
      return response.data.data.updateCleaningSettings;
    } else {
      throw Error(response?.data?.errors[0]?.extensions?.code);
    }
  }

  /***** Edit Datafile Title ****/
  async editDatafileTitle(datafileId, datafileTitle) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation editDatafileTitle($datafileId: ID!, $datafileTitle: String!) {
            editDatafileTitle(datafileId: $datafileId, datafileTitle: $datafileTitle) {
              status
              message
            }
          }
        `,
        variables: {
          datafileId: datafileId,
          datafileTitle: datafileTitle
        }
      }
    });
    if (response?.data?.data) {
      return response.data.data.editDatafileTitle;
    } else {
      throw Error(response?.data?.errors[0]?.extensions?.code);
    }
  }

  /******** Get Datafile Contributors *******/
  async getDatafileContributors(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAllDatafileContributors($datafileId: ID!) {
            getAllDatafileContributors(datafileId: $datafileId) {
              id,
              name {
                first,
                last
              }
              image,
              email,
              accessLevel
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (
      result?.data?.data?.getAllDatafileContributors &&
      !result?.data?.errors
    ) {
      return result.data.data.getAllDatafileContributors;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  /******** Get a list of users who can be added as contributors. *******/
  async getAvailableUsersAsContributorsForDataFile(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAvailableUsersAsContributorForDatafile($datafileId: ID!) {
            getAvailableUsersAsContributorForDatafile(datafileId: $datafileId) {
              id,
              name {
                first,
                last
              }
              image,
              email,
              accessLevel
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (
      result?.data?.data?.getAvailableUsersAsContributorForDatafile &&
      !result?.data?.errors
    ) {
      return result.data.data.getAvailableUsersAsContributorForDatafile;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

   /******** Add Contributor To Datafile *******/
  async addContributorToDatafile(datafileID, inviteeIDs) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation addContributorToDatafile($datafileID: ID!, $inviteeIDs: [ID]!) {
            addContributorToDatafile(datafileId: $datafileID, inviteeIds: $inviteeIDs) {
              status
              message
            }
          }
        `,
        variables: {
          datafileID: datafileID,
          inviteeIDs: inviteeIDs
        }
      }
    });

    if (result?.data?.data?.addContributorToDatafile && !result?.data?.errors) {
      return result.data.data.addContributorToDatafile.status;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  /******** Remove Contributor From Datafile *******/
  async removeContributorFromDatafile(datafileID, contributorID) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation removeContributorFromDatafile($datafileID:ID!, $contributorID: ID!) {
            removeContributorFromDatafile(datafileId: $datafileID, contributorId: $contributorID) {
              status
              message
            }
          }
        `,
        variables: {
          datafileID: datafileID,
          contributorID: contributorID
        }
      }
    });

    if (result?.data?.data?.removeContributorFromDatafile && !result?.data?.errors) {
      return result.data.data.removeContributorFromDatafile.status;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  /******** Get Data Record Summary fo Data file *******/
  async getDataRecordSummaryForDatafile(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getDataRecordSummaryForDatafile($datafileId: ID!) {
            getDataRecordSummaryForDatafile(datafileId: $datafileId) {
              totalNumberOfDataRecords
              numberOfDataRecordsWithValidScore
              numberOfDataRecordsWithInvalidScore
              numberOfExcludedDataRecords
              numberOfIncludedDataRecords
            }
          }
        `,
        variables: {
          datafileId: datafileId
        }
      }
    });

    if (
      result?.data?.data?.getDataRecordSummaryForDatafile &&
      !result?.data?.errors
    ) {
      return result.data.data.getDataRecordSummaryForDatafile;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  async getSheetNames(datafileKey, companyId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getSheetNames($datafileKey: String!, $companyId: ID!) {
            getSheetNames(datafileKey: $datafileKey, companyId: $companyId)
          }
        `,
        variables: {
          datafileKey: datafileKey,
          companyId: companyId
        }
      }
    });

    if (result?.data?.data?.getSheetNames && !result?.data?.errors) {
      return result.data.data.getSheetNames;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }
}

export default new DatafileService();
