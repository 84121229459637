<script>
import DeleteUserOverlay from '../overlays/delete-user-overlay.vue'
import ResendUserInvitationOverlay from '../overlays/resend-user-invitation-overlay.vue'
import AccessLevels from "@/shared/enums/aceessLevels";

export default {
  props: {
    user: Object
  },
  components: {
    DeleteUserOverlay,
    ResendUserInvitationOverlay
  },
  data() {
    return {
      accessLevels: AccessLevels,
      /****** Operational variable ******/
      isDisplayDeleteUserOverlay: false,
      isDisplayResendUserInvitationOverlay: false
    }
  },
  methods: {
    clickDeletInvitation() {
      this.isDisplayDeleteUserOverlay = true;
    },
    clickResendInvitation() {
      this.isDisplayResendUserInvitationOverlay = true;
    }
  }
}
</script>

<template>
  <div class="card" style="height: 105px">
    <div class="card-body">
      <div class="card-text">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-2">
            <img
              src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png"
              class="rounded-circle"
              style="height: 60px"
            />
          </div>
          <div class="col-md-8">
            <div style="font-size: medium; font-weight: 600"> {{ this.user.name.first }} {{ this.user.name.last }}</div>
            <div class="mt-1"> {{ this.user.email }} </div>
            <div class="mt-1" v-if="this.user.accessLevel === accessLevels.ADMIN">
              <b-badge style="font-size: 12px" variant="success">
                {{ this.user.accessLevel }}
              </b-badge>
            </div>
          </div>
          <div class="col-md-2 text-right">
            <b-dropdown
              size="sm"
              variant="light"
              dropright
            >
              <template v-slot:button-content>
                <i class="bx bx-dots-vertical-rounded" style="font-size: large;"></i>
              </template>
              <!-- Re-Send Invitation -->
              <b-dropdown-item @click="clickResendInvitation()">
                <i class="bx bx-sync mr-1"></i>
                {{$t("adminComponents.manageUsersWidget.pendingUserCard.resendInvitationButton")}}
              </b-dropdown-item>

              <!-- Delete Inivation -->
              <b-dropdown-item @click="clickDeletInvitation()">
                <i class="bx bx-trash mr-1"></i>
                {{$t("adminComponents.manageUsersWidget.pendingUserCard.deleteInvitationButton")}}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete User Overlay -->
    <DeleteUserOverlay
      v-if="isDisplayDeleteUserOverlay === true"
      :visible="isDisplayDeleteUserOverlay"
      @close="isDisplayDeleteUserOverlay = false"
      :user="this.user"
    />

    <!-- Resend User Invitation Overlay -->
    <ResendUserInvitationOverlay
      v-if="isDisplayResendUserInvitationOverlay === true"
      :visible="isDisplayResendUserInvitationOverlay"
      @close="isDisplayResendUserInvitationOverlay = false"
      :user="this.user"
    />
  </div>
</template>

