<script>
import { EventBus } from "@/shared/util/event-bus.js";
import ContractService from "@/api/services/contract.service.js";
import { calculateNumberOfDays, getErrorMessageByErrorCode, getDateAccoringTolanguage, getCreditsAccoringTolanguage } from "@/shared/util/helpers.js";
import { CreditBalanceChart } from "../charts.js"

export default {
  props: {
    contract: Object
  },
  data() {
    return {
      /***** Data variables *****/
      contractDetails: null,
      creditBalanceChartOptions: null,
      creditBalancePercentage: [0],
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false,
      dataFetchingnErrorMessage: null
    }
  },
  watch: {
    contract: function() {
      this.isComponentLoading = true;
      this.fetchContractDetails();
      this.isComponentLoading = false;
    }
  },
  async mounted() {
    this.isComponentLoading = true;
    this.creditBalanceChartOptions = new CreditBalanceChart();
    await this.fetchContractDetails();
    this.isComponentLoading = false;
    EventBus.$emit("contractComponetGetLoaded");
  },
  methods: {
    async fetchContractDetails() {
      try {
        this.contractDetails = null;

        this.contractDetails = await ContractService.getContractDetails(this.contract.id);
        await this.setValueToChart();
      } catch(error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    setValueToChart() {
      this.creditBalancePercentage = []
      this.creditBalancePercentage.push((this.contractDetails.primaryCreditPlan.credits.balance / this.contractDetails.primaryCreditPlan.credits.received) * 100);
    },
    formatDate(dateToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getDateAccoringTolanguage(dateToFormat, langaugeToFormat);
    },
    getNumberOfDays(startDate, endDate) {
      return calculateNumberOfDays(startDate, endDate);
    },
    formatNumbers(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCreditsAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
  }
}
</script>

<template>
  <div class="card" style="height: 610px">

    <!-- Component Loading -->
    <div class="card-body" v-if="isComponentLoading">
      <div class="card-text">
        <div class="row pt-5">
          <div class="col-md-12 text-center p-5">
            <b-spinner
              style="width: 5rem; height: 5rem"
              class="m-2"
              variant="mid"
              role="status"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>

    <!-- Display Data -->
    <div class="card-body" v-if="!isComponentLoading && this.contractDetails">
      <div class="card-text">

        <div class="row">
          <div class="col-md-12">
            <span style="font-size: medium; font-weight: 600"> {{contractDetails.name}} </span>
          </div>
        </div>

        <!-- Contract Dates -->
        <div class="row mt-3">
          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.startDate")}}: {{ formatDate(contractDetails.startDate) }}
          </div>

          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.endDate")}}: {{ formatDate(contractDetails.endDate) }}
          </div>

          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.remainingDays")}}: {{ getNumberOfDays(contractDetails.startDate, contractDetails.endDate) }}
          </div>
        </div>

        <!-- Data Record & Data Import State -->
        <div class="row mt-2">
          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.numberOfDataImports")}}: 0
          </div>

          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.numberOfDataRecords")}}: 0
          </div>
        </div>

        <hr />

        <!-- Create by & At -->
        <div class="row mt-2">
          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.createdAt")}}: {{ formatDate(contractDetails.createdAt)}}
          </div>

          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.createdBy")}}: {{contractDetails.createdBy}}
          </div>
        </div>

        <!--Primary Credit Plan -->
        <div class="row mt-5">
          <div class="col-md-4">
            <div class="custom-credit-plan-card">
              <span style="font-size: normal; font-weight: 600"> {{$t("adminComponents.contractsWidget.contractDetails.primaryCreditPlan")}} </span>

              <!-- Wallet Chart -->
              <div class="row mt-1">
                <div class="col-12 text-center">
                  <apexchart
                    ref="CreditBalanceChart"
                    class="apex-charts apexChartData"
                    type="radialBar"
                    height="250"
                    dir="ltr"
                    :series="this.creditBalancePercentage"
                    :options="creditBalanceChartOptions.chartOptions"
                  ></apexchart>
                </div>
              </div>

              <!-- Active Credit Data -->
              <div class="row mt-2 mb-1">
                <div class="col-6 text-center">
                  {{$t("adminComponents.contractsWidget.contractDetails.remaning")}}
                  <div style="font-size: normal; font-weight: 600"> {{ formatNumbers(contractDetails.primaryCreditPlan.credits.balance) }} </div>
                </div>
                <div class="col-6 text-center">
                  {{$t("adminComponents.contractsWidget.contractDetails.received")}}
                  <div style="font-size: normal; font-weight: 600"> {{ formatNumbers(contractDetails.primaryCreditPlan.credits.received) }} </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-credit-plan-card {
  cursor: pointer;
  padding: 10px;
  height: 325px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}
</style>